import React from "react";
import { IoMdSearch } from "react-icons/io";
import { AiOutlineQuestionCircle } from "react-icons/ai";

import clsx from "clsx";
import { Link, useLocation } from "react-router-dom";
import { meta } from "../../../utils/static";

const hide = [
  "/terms",
  "/privacy",
  "/about-us",
  "/why-choose-us",
  "/online-matka",
];
const Navbar = () => {
  const pathname = useLocation();
  return (
    <nav
      className={clsx(
        "flex sticky top-0 z-[51] justify-between items-center bg-white  py-4 px-4 md:px-8",

        hide.includes(pathname) ? "drop-shadow" : "drop-shadow-md"
      )}
    >
      <Link href="/">
        <img
          src="/glogo.svg"
          alt="Logo"
          className="w-[160px] md:w-[180px]  object-contain"
        />
      </Link>
      <div
        className={clsx(
          "flex gap-6 text-gray-600 items-center",

          hide.includes(pathname) ? "hidden" : "flex"
        )}
      >
        <button>
          <IoMdSearch className="text-2xl " />
        </button>
        <button>
          <AiOutlineQuestionCircle className="text-[22px] " strokeWidth={18} />
        </button>
        <button>
          <img src={meta.image} alt="Profile" className="rounded-full size-8" />
        </button>
      </div>
    </nav>
  );
};

export default Navbar;
