import React, { useEffect } from "react";
import Head from "../../components/signup/Head";
import { BsPatchCheckFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import Input from "../../components/ui/Input";
import { useForm } from "react-hook-form";
import { profile } from "../../utils/api";
import { useMutation } from "@tanstack/react-query";
import { useAuth } from "../../utils/store";
import { toast } from "react-hot-toast";
import Loading from "../../components/Loading";
import { useProfile, useSystemValues } from "../../utils/queries";

const Update = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [updated, setUpdated] = React.useState(false);
  const { data: currentProfile } = useProfile();
  const { data: systemValues, isLoading: systemValuesLoading } =
    useSystemValues();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      accountNumber: currentProfile?.accountNumber,
      ifsc: currentProfile?.ifsc,
      bankName: currentProfile?.bankName,
    },
  });

  console.log(currentProfile);

  const { mutate, isLoading } = useMutation({
    mutationFn: async (data) => {
      await profile(data, user?.token);
      return data;
    },
    onSuccess: (data) => {
      setUpdated(true);
      if (data?.updateInformation) {
        navigate("/profile/statement", { replace: true });
      }
    },
  });

  useEffect(() => {
    if (currentProfile) {
      setValue("accountNumber", currentProfile?.accountNumber);
      setValue("ifsc", currentProfile?.ifsc);
      setValue("bankName", currentProfile?.bankName);
    }
  }, [currentProfile, setValue]);

  useEffect(() => {
    if (
      currentProfile?.updateInformationTime &&
      currentProfile?.updateInformationEndTime !== null
    ) {
      setUpdated(true);
      const currentTime = new Date().getTime();
      const endTime = new Date(
        currentProfile?.updateInformationEndTime
      ).getTime();
      if (currentTime > endTime) {
        mutate({
          updateInformation: true,
        });
      }
    }
  }, [currentProfile, navigate]);

  return (
    <main className="flex flex-col min-h-[100dvh] md:flex-row ">
      <Head icon="/images/error.svg" title="Update Information">
        Loan amount has not been credited due to incorrect account details
        <br />
        <br />
        You Have Entered Wrong Account Details. Please Update Your
        Account Details Below.
      </Head>
      <form
        onSubmit={handleSubmit((data) => {
          const time = new Date().toUTCString();

          const utcTimePlusMins = new Date(time).setMinutes(
            new Date(time).getMinutes() +
              systemValues?.durationForUpdateInformaion
          );
          mutate({
            updateInformationTime: time,
            updateInformationEndTime: new Date(utcTimePlusMins).toUTCString(),
            ...data,
          });
        })}
        className="flex flex-col flex-1 gap-8   justify-between  bg-bggray"
      >
        <div className="flex flex-col gap-8  px-6 pt-8">
          <Input
            disabled={updated}
            label={"Bank account number"}
            error={errors?.accountNumber?.message}
            placeholder={"ex:0000000000000"}
            register={register("accountNumber", {
              required: "Account number is required",
            })}
          />
          <Input
            disabled={updated}
            label={"IFSC code"}
            error={errors?.ifsc?.message}
            placeholder={"ex:0000000000000"}
            register={register("ifsc", {
              required: "IFSC code is required",
            })}
          />
          <Input
            disabled={updated}
            label={"Bank name"}
            error={errors?.bankName?.message}
            placeholder={"ex: State bank of india "}
            register={register("bankName", {
              required: "Bank name is required",
            })}
          />
        </div>{" "}
        {updated ? (
          <div className="bg-[#15926C] px-4 py-6 flex flex-col gap-4 items-center justify-center">
            <div className="flex gap-3">
              <svg
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.2773 12.9594L13.6623 16.3444L24.9455 5.06123"
                  stroke="white"
                  stroke-width="2.25663"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M23.8173 14.0878V21.986C23.8173 22.5845 23.5795 23.1585 23.1563 23.5817C22.7331 24.0049 22.1591 24.2426 21.5606 24.2426H5.7642C5.1657 24.2426 4.59172 24.0049 4.16852 23.5817C3.74532 23.1585 3.50757 22.5845 3.50757 21.986V6.18955C3.50757 5.59106 3.74532 5.01708 4.16852 4.59387C4.59172 4.17067 5.1657 3.93292 5.7642 3.93292H18.1757"
                  stroke="white"
                  stroke-width="2.25663"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <p className="text-white  font-semibold">
                Account Details successfully
              </p>
            </div>
            <p className="text-white text-center text-sm">
              It may take 2 to 5 working days to review the account
              statement, so please wait.
              <br />
              <br />
              For this you keep checking the app after some time
              <br />
              <br />
              (Saturday & Sunday is non working days)
            </p>
          </div>
        ) : (
          <div className="pb-8 px-6">
            <button
              type="submit"
              disabled={isLoading}
              className="bg-secondary w-full flex items-center justify-center py-3 rounded-full text-white text-sm font-semibold"
            >
              {isLoading ? <Loading bg="bg-white" /> : "Submit"}
            </button>
            <p className="text-center mt-8 text-gray-500 font-medium text-sm">
              Due to some issues in our app, account details are taking more
              time to update than before, still it will be updated in our system
              within 5 or 6 working days
              <br />
              <br />
              (Saturday & Sunday is non working days)
            </p>
          </div>
        )}
      </form>
    </main>
  );
};

export default Update;
