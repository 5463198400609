import { IoArrowForwardOutline } from "react-icons/io5";
const HeadArrow = ({ children }) => {
  return (
    <div className="flex gap-2 items-center md:justify-start  justify-between">
      <h2 className="text-lg font-medium text-foregroundDark">{children}</h2>
      <button className="flex text-xl items-center rounded-full gap-1 text-gray-600 size-12 justify-center  hover:bg-gray-100">
        <IoArrowForwardOutline />
      </button>
    </div>
  );
};

export default HeadArrow;
