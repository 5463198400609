import React from "react";
import Head from "../../../components/signup/Head";
import Input from "../../../components/ui/Input";
import * as RadioGroup from "@radix-ui/react-radio-group";
import RadioButton from "../../../components/ui/RadioButton";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { Controller, useForm } from "react-hook-form";
import { profile } from "../../../utils/api";
import { toast } from "react-hot-toast";
import { useAuth } from "../../../utils/store";
import Loading from "../../../components/Loading";
const PanCardDetails = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm();

  const { mutate, isLoading } = useMutation({
    mutationFn: (data) => {
      return profile(
        {
          ...data,
          pancardDetailAdded: true,
        },
        user?.token
      );
    },

    onSuccess: (res) => {
      toast.success("Pan Card details added successfully");
      navigate("/sign-up/select-requirements", {
        replace: true,
      });
    },
  });
  return (
    <main className="min-h-[100dvh] flex flex-col md:flex-row">
      <Head title="Pan Card Details" />
      <form
        onSubmit={handleSubmit((data) => {
          mutate(data);
        })}
        className="flex flex-col flex-1 gap-8 px-6  justify-between py-8 bg-bggray"
      >
        <div className="flex flex-col gap-8 ">
          <Input
            label={"Pan Card Number"}
            caption={"As per PAN CARD"}
            error={errors?.pancardNumber?.message}
            register={register("pancardNumber", {
              required: "Pan Card Number is required",
            })}
          />
          <Input
            label={"Date of Birth"}
            caption={"As per PAN CARD"}
            error={errors?.dateOfBirth?.message}
            type={"date"}
            register={register("dateOfBirth", {
              required: "Date of Birth is required",
            })}
          />
          <Input
            label={"Pin Code"}
            error={errors?.pinCode?.message}
            register={register("pinCode", {
              required: "Pin Code is required",
              minLength: {
                value: 6,
                message: "Pin Code should be atleast 6 characters",
              },
            })}
          />
          <Controller
            control={control}
            name="gender"
            rules={{
              required: "Gender is required",
            }}
            render={({ field: { onChange, value } }) => (
              <fieldset className="flex flex-col gap-2">
                <label className="text-xs leading-none text-primary font-semibold ml-3">
                  Gender
                </label>
                <RadioGroup.Root
                  onValueChange={(value) => onChange(value)}
                  value={value}
                  className="flex h-[40px] bg-white rounded-full justify-center  px-6 gap-6 "
                  aria-label="View density"
                >
                  <RadioButton label={"Male"} />
                  <RadioButton label={"Female"} />
                  <RadioButton label={"Other"} />
                </RadioGroup.Root>
                {errors?.gender && (
                  <p className="text-xs text-red-500 ml-3">
                    {errors?.gender?.message}
                  </p>
                )}
              </fieldset>
            )}
          />
        </div>{" "}
        <button
          type="submit"
          disabled={isLoading}
          className="bg-secondary py-3 rounded-full text-white text-sm flex items-center justify-center font-semibold"
        >
          {isLoading ? <Loading bg="bg-white" /> : "Submit"}
        </button>
      </form>
    </main>
  );
};

export default PanCardDetails;
