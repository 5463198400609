import { BiSolidUser } from "react-icons/bi";
import { FiChevronLeft } from "react-icons/fi";
import idea from "../../assets/idea.svg";
import { Link, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useAuth } from "../../utils/store";

const Head = ({ title, description, logo, children, icon, message }) => {
  const { user } = useAuth();
  const navigate = useNavigate();
  useEffect(() => {
    if (!user) {
      navigate("/login");
    }
  }, [user]);

  return (
    <div className="flex flex-col md:flex-[1.5]">
      {" "}
      <section className=" bg-primary flex   items-end  py-6 px-6 justify-between">
        <div className="flex justify-between items-center flex-1">
          {logo ? (
            <h1 className="text-4xl text-secondary uppercase font-akshar ">
              {title}
            </h1>
          ) : (
            <div className="flex gap-4">
              <button onClick={() => navigate(-1)}>
                <FiChevronLeft className="text-white text-2xl  " />
              </button>
              <h1 className="text-xl text-white uppercase ">{title}</h1>
            </div>
          )}
          <Link
            to="/account/profile"
            className="bg-primaryLight  rounded-full p-2.5 "
          >
            <BiSolidUser className="text-white text-xl" />
          </Link>
        </div>
      </section>
      {(description || children) && (
        <section
          className={`flex px-6 py-4  bg-[#866207] ${
            icon ? " " : "items-center"
          }`}
        >
          <img src={icon ? icon : idea} alt="" className="w-8 h-8 mr-2" />
          <p className="text-white text-sm">{description ?? children}</p>
        </section>
      )}
      {message && (
        <p className="bg-[#977112] text-white text-sm px-4 py-6 text-center">
          {message}
        </p>
      )}
    </div>
  );
};

export default Head;
