const Business = () => {
  return (
    <main className="flex flex-col  py-6">
      <h1 className="text-2xl font-semibold container px-6 md:px-14 ">
        Business Loan
      </h1>
      <section className="container px-6 md:px-14 flex flex-col gap-4 py-10">
        <h1 className="text-lg font-semibold">
          Broadly there are 8 Types of Business Loans in India:
        </h1>

        <ul className="list-disc list-outside text-sm text-gray-500 flex flex-col gap-3">
          <li>Term Loan (Short & Long-term Loan)</li>
          <li>Working Capital Loan</li>
          <li>Letter of Credit</li>
          <li>Bill/Invoice Discounting</li>
          <li>Overdraft Facility</li>
          <li>Equipment Finance or Machinery Loan</li>
          <li>Loans under Govt. schemes</li>
          <li>POS Loans or Merchant Cash Advance</li>
        </ul>
      </section>
      <div className="bg-secondary bg-opacity-5 py-10">
        <section className="container px-6 md:px-14 flex flex-col gap-4">
          <h1 className="text-lg font-semibold">1) Term Loan</h1>
          <p className="text-sm text-gray-500">
            Term loan is a loan that is required to be repaid in regular
            payments over a set period of time. The term loan is categorized
            into short-term, intermediate-term and long-term loans. The
            repayment tenure of these two types ranges between 12 months to 5
            years. Term loans that are of a shorter duration which is of 12
            months are called short-term loans and loans up to 5 years or more
            are long-term loans. The collateral-free business loans are offered
            up to Rs. 2 crore, also can exceed depending upon business
            requirements. The repayment tenure for a term loan is finalized by
            the lender at the time of loan application.
          </p>
        </section>
      </div>{" "}
      <section className="container px-6 md:px-14 flex flex-col gap-4 py-10">
        <h1 className="text-lg font-semibold">2) Working Capital Loan</h1>

        <p className="text-sm text-gray-500">
          Working capital loans are used by individuals, entrepreneurs,
          startups, and MSMEs to meet their daily business requirements and for
          various business expansion services, enhancing business cash flow,
          purchasing raw materials, addition in inventory/stock, paying
          salaries, hiring staff, etc. Working capital loans are majorly
          short-term loans of the loan amount up to Rs. 40 lakh wherein the
          repayment tenure is up to 12 months or may exceed business
          requirements. The interest rate offered by Banks/NBFCs is a bit
          higher, as compared to long-term loans or general business loans. In
          this type of loan, the lender sets a limit for the business to take a
          loan and the amount can be utilized for specific business purposes,
          only.
        </p>
      </section>
      <div className="bg-secondary bg-opacity-5 py-10">
        <section className="container px-6 md:px-14 flex flex-col gap-4">
          <h1 className="text-lg font-semibold">3) Letter of Credit</h1>
          <p className="text-sm text-gray-500">
            Letter of credit is a type of credit limit used majorly in trading
            businesses in which the bank or lender offers a funding guarantee to
            enterprises that deal in international trade. Letter of credit can
            be utilized for both import and export purposes by entrepreneurs.
            Enterprises doing business overseas tend to deal with unknown
            suppliers, so for that, they require assurance of payment before
            performing any transaction. Therefore, a letter of credit plays a
            vital role in providing payment assurance to the suppliers.
          </p>
        </section>
      </div>{" "}
      <section className="container px-6 md:px-14 flex flex-col gap-4 py-10">
        <h1 className="text-lg font-semibold">4) Bill Discounting</h1>

        <p className="text-sm text-gray-500">
          Bill or Invoice Discounting is a funding facility in which the seller
          gets an amount in advance at discounted rates from the lender. This
          asks buyers to contribute in the form of interest rate in increasing
          the revenue of the financial institutions, in form of interest paid
          and from the monthly fee.
        </p>
        <p className="text-sm text-gray-500">
          For example, You have sold goods to Mr. Singh, he has given you a
          letter of credit from the bank for 45 days, if you want to get money
          from the bank before 45 days, the bank will charge some interest rate
          from you, which in return will be called a discount for the seller.
          Further, let’s assume that the amount which you were supposed to get
          was Rs. 10 lakh on or after 45 days, by bank’s discount or interest
          rate of Rs. 50,000 you now get Rs. 9,50,000 in return from the bank.
          The buyer will anyhow deposit Rs. 10 lakh to the respective bank on
          the 45th day only.{" "}
        </p>
      </section>
      <div className="bg-secondary bg-opacity-5 py-10">
        <section className="container px-6 md:px-14 flex flex-col gap-4">
          <h1 className="text-lg font-semibold">5) Overdraft Facility</h1>
          <p className="text-sm text-gray-500">
            Overdraft facility is a funding type offered by a bank to its
            account holder to withdraw cash from his/her account even if the
            account balance is zero. The interest rate is charged only on the
            utilized amount from the sanctioned limit and on a daily basis. The
            credit limit that is sanctioned depends upon the account holder’s
            relationship with the bank, credit history, cash flows, and
            repayment history if any. The overdraft limit is revised every year
            and can be used in any manner if the interest is paid on time. An
            overdraft facility is offered against collateral/securities,
            especially in terms of FDs with the bank.
          </p>
        </section>
      </div>{" "}
      <section className="container px-6 md:px-14 flex flex-col gap-4 py-10">
        <h1 className="text-lg font-semibold">
          6) Equipment Finance or Machinery Loan
        </h1>

        <p className="text-sm text-gray-500">
          The equipment finance or machinery loan is a funding option offered to
          the borrowers for them to purchase new equipment/machinery or to
          upgrade the existing one. Equipment finance is used mainly by large
          enterprises and enterprises engaged in the manufacturing sector.
          Enterprises or business owners availing equipment finance or machinery
          loan also enjoy tax benefits. The interest rate, loan amount, and
          repayment tenure offered shall vary from lender to lender.
        </p>
      </section>
      <div className="bg-secondary bg-opacity-5 py-10">
        <section className="container px-6 md:px-14 flex flex-col gap-4">
          <h1 className="text-lg font-semibold">
            7) Loans under Govt. Schemes
          </h1>
          <p className="text-sm text-gray-500">
            The Government of India has initiated various loan schemes for
            individuals, MSMEs, women entrepreneurs, and other entities engaged
            in trading, services, and manufacturing sectors. The loans under
            government schemes are offered by various financial institutions,
            such as Private and Public Sector Banks, NBFCs, Regional Rural Banks
            (RRBs), Micro Finance Institutions (MFIs), Small Finance Banks
            (SFBs), etc. Some of the leading Govt. Loan schemes include Mudra
            Scheme under PMMY, PMEGP, CGTMSE, Standup India, Startup India, PSB
            Loans in 59 minutes, PMRY, etc.{" "}
          </p>
        </section>
      </div>{" "}
      <section className="container px-6 md:px-14 flex flex-col gap-4 py-10">
        <h1 className="text-lg font-semibold">8) Point-of-Sale (POS) Loans</h1>

        <p className="text-sm text-gray-500">
          POS Loans or Merchant Cash Advance is a mechanism in which a business
          owner running an enterprise pays a lump sum amount in advance to
          suppliers through his/her daily or future credit or debit card
          transactions. Several times, merchants of SMEs experience a short-term
          cash crunch. Hence, to reduce the liquidity crunch in the business,
          merchants opt for POS loans. The interest rate offered under POS loans
          is comparatively higher, as compared to other business loan types. The
          repayment facility is linked with debit or credit transactions Point
          of Sales (POS) machines installed at retail shops, grocery stores,
          supermarkets, and shopping malls.{" "}
        </p>

        <p className="text-sm text-gray-500">
          As of now, you must have got a rough idea about the types of business
          loans offered by lending institutions in India. Business loans can be
          availed at nominal and attractive interest rates with flexible and
          easy EMIs. The best business loan deal can be picked by comparing
          various loan deals offered by leading private and public sector banks,
          NBFCs, Regional Rural Banks (RRBs), Small Finance Banks (SFBs), Micro
          Finance Institutions (MFIs), and various other banking and financial
          institutions.
        </p>
      </section>
    </main>
  );
};

export default Business;
