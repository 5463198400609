import React from "react";
import Head from "../../../components/signup/Head";
import Input from "../../../components/ui/Input";
import * as RadioGroup from "@radix-ui/react-radio-group";
import RadioButton from "../../../components/ui/RadioButton";
import { useNavigate } from "react-router-dom";
import img from "../../../assets/takephoto.svg";
import { Controller, useForm } from "react-hook-form";
import { profile } from "../../../utils/api";
import { useAuth } from "../../../utils/store";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-hot-toast";
import Loading from "../../../components/Loading";
import Webcam from "react-webcam";
import { MdDelete } from "react-icons/md";
const TakePhoto = () => {
  const navigate = useNavigate();
  const { register, handleSubmit, control, getValues, watch } = useForm();
  const ref = React.useRef();
  const { user } = useAuth();
  const photo = watch("photo");
  const [photoUrl, setPhotoUrl] = React.useState(null);
  const { mutate, isLoading } = useMutation({
    mutationFn: async (data) => {
      return await profile(data, user?.token);
    },
    onSuccess: (res) => {
      toast.success("Photo added successfully");
      navigate("/sign-up/bank-info", {
        replace: true,
      });
    },
  });
  const videoConstraints = {
    width: 1280,
    height: 720,
    facingMode: "user",
  };
  const webcamRef = React.useRef(null);

  const capture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setPhotoUrl(imageSrc);
  }, [webcamRef]);

  return (
    <main className="min-h-[100dvh] flex flex-col md:flex-row">
      <Head title="BharatCash" logo />
      <form
        // navigate("/sign-up/bank-info");
        onSubmit={handleSubmit((data) => {
          if (!photoUrl) {
            return toast.error("Please take a photo");
          }
          const formData = new FormData();
          formData.append("photo", photoUrl);
          formData.append("photoAdded", true);
          mutate(formData);
        })}
        className="flex flex-col flex-1 gap-8 px-6  justify-between py-8 bg-bggray"
      >
        <div className="flex flex-col gap-8  bg-white rounded-lg px-6 py-8">
          {photoUrl ? (
            <div className="relative">
              <button
                type="button"
                onClick={() => setPhotoUrl(null)}
                className="absolute top-2 right-2 bg-white text-red-500 rounded-full p-2"
              >
                <MdDelete />
              </button>

              <img
                src={photoUrl}
                alt="photo"
                className="rounded-lg object-cover"
              />
            </div>
          ) : (
            <>
              <Webcam
                audio={false}
                height={720}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                width={1280}
                videoConstraints={videoConstraints}
                className="rounded-lg"
              />
              <button
                type="button"
                onClick={capture}
                className=" gap-2 flex-1 bg-secondary text-white px-6 py-2 rounded-full  flex items-center justify-center"
              >
                {/* photo name */}
                Capture
              </button>
            </>
          )}

          <p className="text-center text-primary  ">
            For Processing Your Funds You Have To Verify Your Face I&apos;d
          </p>
          <h1 className="text-center text-2xl text-primary font-semibold">
            Take a photo - ID front
          </h1>
          <ul className="flex flex-col gap-2 ">
            <li className="flex items-start  gap-2">
              <div className="min-w-[10px] mt-1 min-h-[10px] rounded-full bg-secondary"></div>
              <p className="text-sm text-primary">
                Place ID on a plain dark surface and make sure all four corners
                visible.
              </p>
            </li>
          </ul>
        </div>{" "}
        <button
          type="submit"
          className="bg-secondary py-3 flex items-center justify-center rounded-full text-white text-sm font-semibold"
        >
          {isLoading ? <Loading bg="bg-white" /> : "Next"}
        </button>
      </form>
    </main>
  );
};

export default TakePhoto;
