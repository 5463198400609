import { useQuery } from "@tanstack/react-query";
import { getProfile, getSystemValues } from "./api";
import { useAuth } from "./store";

export const useProfile = () => {
  const { user } = useAuth();
  return useQuery({
    queryKey: ["profile"],
    queryFn: async () => await getProfile(user?.token),
  });
};

export const useSystemValues = () => {
  return useQuery({
    queryKey: ["systemValues"],
    queryFn: async () => {
      return await getSystemValues();
    },
  });
};
