import { FiInfo } from "react-icons/fi";
import { IoStar } from "react-icons/io5";
import { Link } from "react-router-dom";
const Rating = () => {
  return (
    <div className="flex gap-4 mt-4 items-center">
      <img
        src="/logo.png"
        alt="Playstore"
        className="rounded-lg shadow size-12 hidden md:block"
      />
      <div className="flex  items-center w-full md:w-auto">
        <div className="flex flex-col gap-1 flex-1 md:flex-auto  md:pr-6 items-center">
          <h2 className="text-white text-sm font-semibold inline-flex items-center">
            4.9
            <IoStar className="text-xs " />
          </h2>
          <p className="text-foreground text-xs">39000 Reviews</p>
        </div>
        <div className="w-px h-6 bg-darkForeground" />
        <div className="flex flex-col gap-1 md:px-6  items-center flex-1 md:flex-auto">
          <h2 className="text-white text-sm font-semibold inline-flex items-center">
            1M+
          </h2>
          <p className="text-foreground text-xs">Downloads</p>
        </div>
        <div className="w-px h-6 bg-darkForeground" />
        <div className="flex flex-col gap-2 md:pl-6   items-center flex-1 md:flex-auto">
          <img
            src="/18+.webp"
            alt="18+"
            className="size-5 md:size-6 object-contain"
          />
          <p className="text-foreground text-xs gap-1 flex items-center ">
            Rated for 18+
            <Link to="/install">
              <FiInfo className="text-xs " />
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Rating;
