import { MdOutlineDevices } from "react-icons/md";

import clsx from "clsx";
import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import { LuBookmarkPlus, LuLoader2 } from "react-icons/lu";
import { Link } from "react-router-dom";
import { meta } from "../../../../utils/static";
import { installedPwa } from "../../../../utils/store";
import Rating from "./Rating";
const Hero = () => {
  const [supportsPWA, setSupportsPWA] = useState(false);
  const [installed, setInstalled] = useAtom(installedPwa);

  const [promptInstall, setPromptInstall] = useState(null);

  const [loading, setLoading] = useState(false);

  return (
    <section className="flex flex-col-reverse  md:block  w-full bg-bg md:aspect-[15/7.2] md:max-h-[42rem]  relative">
      <div className="md:absolute inset-0  h-full pb-10 z-[50] flex container flex-col gap-4  justify-end">
        <div className="flex gap-6">
          <div
            className={clsx(
              " md:hidden bg-white rounded-2xl  relative",
              loading ? "size-[60px] rounded-full " : "size-[72px]"
            )}
          >
            {loading && (
              <LuLoader2
                strokeWidth={1}
                className="animate-spin text-gprimary text-8xl  absolute inset-0 -left-[18px] m-auto"
              />
            )}
            <img
              src={meta.image}
              alt="logo"
              className="rounded-full shadow w-full object-contain h-full "
            />
          </div>
          <div className="flex flex-col gap-2 md:gap-4 flex-1">
            <h1 className="text-2xl md:text-4xl xl:text-5xl max-w-lg font-medium xl:font-semibold text-white">
              {meta.name}
            </h1>
            <Link
              to={"/install"}
              className=" text-gprimary font-medium text-base "
            >
              {meta.company}
            </Link>
          </div>
        </div>
        <Rating />
        <div className="flex  flex-col md:flex-row gap-6 md:gap-8 mt-4 items-center ">
          <a
            download
            href={"/app-release.apk"}
            className="bg-gprimary-light text-center w-full md:w-auto shadow-sm text-sm lg:text-[15px]  font-medium text-bg px-5 py-2 lg:px-8 lg:py-3 rounded-lg"
          >
            Install Application
          </a>

          <div className="flex gap-3 items-center text-gprimary">
            <LuBookmarkPlus className="text-xl " />
            <p className="text-sm md:hidden lg:block">Add to wishlist</p>
          </div>
        </div>
        <div className="flex gap-3 items-center mt-8 md:mt-4 text-foreground">
          <MdOutlineDevices className="text-xl" />
          <p className=" text-sm">This app is available for your device</p>
        </div>
      </div>
      <div className="md:w-2/3 md:ml-auto md:h-[90%] relative">
        <img
          src={meta.banner}
          alt="background"
          className=" h-full aspect-[16/8] w-full md:aspect-auto object-cover"
        />
        <div className="absolute md:block hidden top-0 left-0 w-full h-full bg-gradient-to-r from-bg to-transparent " />
        <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-t from-bg to-transparent " />
      </div>
    </section>
  );
};

export default Hero;
