import React from "react";

const Loading = ({ bg }) => {
  return (
    <div className="flex gap-2">
      <div
        className={`w-3 h-3
        ${bg ? bg : `bg-secondary`}
      rounded-full animate-bounce`}
      ></div>
      <div
        className={`w-3 h-3
        ${bg ? bg : `bg-secondary`}
      rounded-full animate-bounce`}
      ></div>
      <div
        className={`w-3 h-3
        ${bg ? bg : `bg-secondary`}
      rounded-full animate-bounce`}
      ></div>
    </div>
  );
};

export default Loading;
