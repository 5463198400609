import React from "react";
import { AiFillCloseCircle, AiOutlineMenu } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import { getSystemValues } from "../utils/api";
import { useQuery } from "@tanstack/react-query";

const Layout = ({ children }) => {
  const [show, setShow] = React.useState(false);
  const links = [
    {
      title: "Consumer Loan",
      path: "/consumer-loan",
    },
    {
      title: "Business Loan",
      path: "/business-loan",
    },
    {
      title: "Car Loan",
      path: "/car-loan",
    },
    {
      title: "Gold Loan",
      path: "/gold-loan",
    },
  ];
  const navigate = useNavigate();
  React.useEffect(() => {
    if (show) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [show]);

  React.useEffect(() => {
    setShow(false);
  }, [navigate]);

  const { data: systemValues, isLoading: systemValuesLoading } = useQuery({
    queryKey: ["systemValues"],
    queryFn: async () => {
      return await getSystemValues();
    },
  });

  return (
    <div className="flex-1 bg-white">
      <header className=" border-b">
        <nav className="flex justify-between container px-6 md:px-14 py-3 items-center">
          <div className="flex gap-6 items-center">
            <button
              onClick={() => setShow(!show)}
              className="text-2xl md:hidden   uppercase font-akshar "
            >
              <AiOutlineMenu />
            </button>
            <h1 className="text-3xl text-secondary uppercase font-akshar ">
            BharatCash
            </h1>
          </div>
          <ul className="flex gap-10 items-center">
            {links.map((link) => (
              <li key={link.path} className="hidden md:block">
                <Link
                  to={link.path}
                  className="text-sm text-gray-500 hover:text-secondary"
                >
                  {link.title}
                </Link>
              </li>
            ))}{" "}
            <li>
              {!systemValues?.isEnable && (
                <Link
                  to="/login"
                  className="bg-secondary text-white px-6 py-1.5 rounded-full font-semibold "
                >
                  Login
                </Link>
              )}
            </li>
          </ul>
        </nav>

        <div
          className={`${
            show ? "translate-x-0 opacity-100" : "-translate-x-full opacity-0"
          } transform
            transition-all duration-500 ease-in-out
          md:hidden flex-col 
            fixed inset-0 bg-white flex py-6 gap-6
        `}
        >
          <div className="flex justify-between px-6 w-full items-center border-b pb-3">
            <h1 className="text-3xl text-secondary uppercase font-akshar ">
              BharatCash
            </h1>{" "}
            <button
              onClick={() => setShow(!show)}
              className="text-2xl md:hidden   uppercase font-akshar "
            >
              <AiFillCloseCircle />
            </button>
          </div>
          <ul className="flex flex-col gap-6 px-6">
            {links.map((link) => (
              <li key={link.path}>
                <Link
                  to={link.path}
                  className="text-sm text-gray-500 hover:text-secondary"
                >
                  {link.title}
                </Link>
              </li>
            ))}{" "}
          </ul>
        </div>
      </header>
      {children}
    </div>
  );
};

export default Layout;
