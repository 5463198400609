import { useQuery } from "@tanstack/react-query";
import { Link, useNavigate } from "react-router-dom";
import { getSystemValues } from "../../utils/api";
import { useEffect } from "react";

const Information = () => {
  const navigate = useNavigate();
  const { data: systemValues, isLoading: systemValuesLoading } = useQuery({
    queryKey: ["systemValues"],
    queryFn: async () => {
      return await getSystemValues();
    },
  });

  useEffect(() => {
    if (!systemValues?.isEnable) {
      navigate("/login", {
        replace: true,
      });
    }
  }, [navigate, systemValues]);
  return (
    <main className="flex flex-col gap-6 container py-6 px-6 md:px-14 md:items-center md:justify-center">
      <h1 className="text-xl font-semibold  ">Types of Loans</h1>
      <section className="grid grid-cols-2 gap-6 md:w-[25rem] md:bg-gray-50 md:rounded-md md:shadow md:p-5">
        {[
          {
            img: "/icons/consumer.png",
            title: "Consumer Loan",
            link: "/consumer-loan",
          },
          {
            img: "/icons/gold.png",
            title: "Gold Loan",
            link: "/gold-loan",
          },
          {
            img: "/icons/car-loan.png",
            title: "Car Loan",
            link: "/car-loan",
          },
          {
            img: "/icons/business.png",
            title: "Business Loan",
            link: "/business-loan",
          },
        ].map((loan) => (
          <Link
            to={loan.link}
            key={loan.link}
            className="bg-secondary  flex flex-col gap-3 items-center justify-center px-2 py-4  rounded-lg"
          >
            <img
              src={loan.img}
              alt=""
              className="w-1/2 aspect-square object-contain"
            />
            <h1 className="text-white text-sm text-center ">{loan.title}</h1>
          </Link>
        ))}
      </section>
    </main>
  );
};

export default Information;
