import React from "react";
import Head from "../../components/signup/Head";
import { getSystemValues } from "../../utils/api";
import { useQuery } from "@tanstack/react-query";

const Terms = () => {
  const { data: data2, isLoading: isLoading2 } = useQuery({
    queryKey: ["systemValues"],
    queryFn: async () => {
      return await getSystemValues();
    },
  });
  return (
    <main className="bg-white">
      <Head title="Terms and Conditions" />
      <div className="flex flex-col gap-6 text-black flex-1 px-[6%] py-6 container bg-white">
        {data2?.termsAndConditions}
      </div>
    </main>
  );
};

export default Terms;
