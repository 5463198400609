import React from "react";
import * as Select from "@radix-ui/react-select";
import classnames from "classnames";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import { BiCheck } from "react-icons/bi";
import { Controller } from "react-hook-form";
const CustomSelect = ({ label, options, control, name }) => (
  <fieldset className="flex flex-col gap-2">
    <label className="text-xs leading-none text-primary font-semibold ml-3">
      {label}
    </label>
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => (
        <Select.Root onValueChange={(value) => onChange(value)} value={value}>
          <Select.Trigger
            className="inline-flex items-center justify-between rounded-full px-[15px] text-[13px] leading-none h-[40px] gap-[5px] bg-white text-primary   focus:shadow-[0_0_0_2px] focus:shadow-black data-[placeholder]:text-primary data-[placeholder]:text-opacity-50  outline-none"
            aria-label={label}
          >
            <Select.Value placeholder={"Select"} />
            <Select.Icon className="text-primary">
              <FiChevronDown />
            </Select.Icon>
          </Select.Trigger>
          <Select.Portal>
            <Select.Content className="overflow-hidden bg-white rounded-md shadow-[0px_10px_38px_-10px_rgba(22,_23,_24,_0.35),0px_10px_20px_-15px_rgba(22,_23,_24,_0.2)]">
              <Select.ScrollUpButton className="flex items-center justify-center h-[25px] bg-white text-violet11 cursor-default">
                <FiChevronUp />
              </Select.ScrollUpButton>
              <Select.Viewport className="p-[5px]">
                {options?.map((option) => (
                  <SelectItem key={option.value} value={option.value}>
                    {option.label}
                  </SelectItem>
                ))}
              </Select.Viewport>
              <Select.ScrollDownButton className="flex items-center justify-center h-[25px] bg-white text-violet11 cursor-default">
                <FiChevronDown />
              </Select.ScrollDownButton>
            </Select.Content>
          </Select.Portal>
        </Select.Root>
      )}
    />
  </fieldset>
);

const SelectItem = React.forwardRef(
  ({ children, className, ...props }, forwardedRef) => {
    SelectItem.displayName = "SelectItem";
    return (
      <Select.Item
        className={classnames(
          "text-[13px] leading-none text-violet11 rounded-[3px] flex items-center h-[25px] pr-[35px] pl-[25px] relative select-none data-[disabled]:text-mauve8 data-[disabled]:pointer-events-none data-[highlighted]:outline-none data-[highlighted]:bg-violet9 data-[highlighted]:text-violet1",
          className
        )}
        {...props}
        ref={forwardedRef}
      >
        <Select.ItemText>{children}</Select.ItemText>
        <Select.ItemIndicator className="absolute left-0 w-[25px] inline-flex items-center justify-center">
          <BiCheck />
        </Select.ItemIndicator>
      </Select.Item>
    );
  }
);

export default CustomSelect;
