const Input = ({
  label,
  caption,
  placeholder,
  register,
  error,
  type,
  disabled,
}) => {
  return (
    <fieldset className="flex flex-col gap-2">
      <div className="flex items-center justify-between">
        <label className="text-xs leading-none text-primary font-semibold ml-3">
          {label}
        </label>
        {error && <p className="text-red-500 text-xs">{error}</p>}
      </div>
      <input
        {...register}
        disabled={disabled}
        type={type || "text"}
        placeholder={placeholder}
        className=" rounded-full px-[15px] text-[13px] leading-none h-[40px] bg-white text-primary   focus:shadow-[0_0_0_2px] focus:shadow-black data-[placeholder]:text-primary data-[placeholder]:text-opacity-50  outline-none"
      />
      {caption && (
        <p className="text-xs leading-none text-primary text-opacity-50 font-semibold ml-3">
          {caption}
        </p>
      )}
    </fieldset>
  );
};

export default Input;
