import React from "react";
import Head from "../../../components/signup/Head";
import Input from "../../../components/ui/Input";
import * as Slider from "@radix-ui/react-slider";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useMutation } from "@tanstack/react-query";
import { profile } from "../../../utils/api";
import { toast } from "react-hot-toast";
import { useAuth } from "../../../utils/store";
import CustomSelect from "../../../components/ui/Select";
import Loading from "../../../components/Loading";
import { AiOutlineLoading } from "react-icons/ai";
const SelectRequirements = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm();
  const [value, setValue] = React.useState([250000]);
  const [currentLoading, setCurrentLoading] = React.useState(false);
  const { mutate, isLoading } = useMutation({
    mutationFn: (data) => {
      return profile(
        {
          ...data,
          // 95% value
          amount: value?.[0] * 0.95,
          amountDetailAdded: true,
        },
        user?.token
      );
    },

    onSuccess: (res) => {
      setTimeout(() => {
        setCurrentLoading(false);
        toast.success("Amount details added successfully");
        navigate("/sign-up/loan-status", {
          replace: true,
        });
      }, 5000);
    },
  });
  return (
    <main className="min-h-[100dvh] flex flex-col md:flex-row">
      <Head title="BharatCash" logo />
      <form
        onSubmit={handleSubmit((data) => {
          setCurrentLoading(true);
          mutate(data);
        })}
        className="flex flex-col relative flex-1 gap-8 px-6  justify-between py-8 bg-bggray"
      >
        {currentLoading && (
          <section className="absolute inset-0 bg-black bg-opacity-10 flex items-center justify-center">
            <div className="bg-white px-6 py-4 flex gap-4 rounded-lg text-sm w-2/3 items-center">
              <AiOutlineLoading className="animate-spin text-5xl text-secondary" />
              <p>
                We are processing your Profile and Fetching your Credit Limit
              </p>
            </div>
          </section>
        )}
        <div className="flex flex-col gap-8 ">
          <h2 className="text-lg text-primary font-semibold  text-center">
            Please Select your Amount and tenure as per your requirements
          </h2>
          <fieldset className="flex flex-col gap-2">
            <label className="text-xs leading-none text-primary font-semibold ml-3">
              Select amount
            </label>

            <div className="font-semibold rounded-full px-[15px] flex items-center justify-center text-[13px] leading-none h-[40px] bg-white text-primary   focus:shadow-[0_0_0_2px] focus:shadow-black data-[placeholder]:text-primary data-[placeholder]:text-opacity-50  outline-none">
              {value}
            </div>
          </fieldset>
          <div className="flex flex-col">
            <Slider.Root
              className="relative flex items-center select-none touch-none  h-5"
              defaultValue={value}
              max={500000}
              step={5000}
              min={5000}
              onValueChange={(value) => setValue(value)}
            >
              <Slider.Track className="bg-gray-300 relative grow rounded-full h-[3px]">
                <Slider.Range className="absolute bg-secondary rounded-full h-full" />
              </Slider.Track>
              <Slider.Thumb
                className="block w-5 h-5 bg-secondary  rounded-[10px] hover:bg-violet3 focus:outline-none focus:shadow-[0_0_0_5px] focus:shadow-blackA8"
                aria-label="Volume"
              />
            </Slider.Root>

            <div className="flex justify-between">
              <span className="text-xs text-primary font-semibold">₹ 5000</span>
              <span className="text-xs text-primary font-semibold">
                ₹ 5,00,000
              </span>
            </div>
          </div>
          <CustomSelect
            label={"Select tenure"}
            control={control}
            name={"tenure"}
            // "3 Months",
            // "6 Months",
            // "1 Year",
            // "2 Year",
            // "3 Year",
            // "4 Year",
            // "5 Year",

            options={[
              { value: "3 Months", label: "3 Months" },

              { value: "6 Months", label: "6 Months" },
              { value: "1 Year", label: "1 Year" },
              { value: "2 Year", label: "2 Year" },
              { value: "3 Year", label: "3 Year" },
              { value: "4 Year", label: "4 Year" },
              { value: "5 Year", label: "5 Year" },
            ]}
          />
        </div>{" "}
        <button
          type="submit"
          disabled={isLoading}
          className="bg-secondary py-3 flex items-center justify-center rounded-full text-white text-sm font-semibold"
        >
          Next
        </button>
      </form>
    </main>
  );
};

export default SelectRequirements;
