const Gold = () => {
  return (
    <main className="flex flex-col  py-6">
      <h1 className="text-2xl font-semibold container px-6 md:px-14 ">
        Gold Loan
      </h1>
      <section className="container px-6 md:px-14 flex flex-col gap-4 py-10">
        <h1 className="text-lg font-semibold">What is Gold Loan?</h1>
        <p className="text-sm text-gray-500">
          One of the world's biggest users of gold is India. According to the
          World Gold Council (WGC), India's annual demand for gold surged by
          804% between 1987 and 2016. It can be used as collateral for gold
          loans, which are frequently used to get funds for both long- and
          short-term purposes. The gold loan is actually one of the simplest and
          quickest ways to get money when you need it.
        </p>
        <p className="text-sm text-gray-500">
          The gold loan may be your best financial option, even if you have lots
          of unused gold in your safe but a low credit score. With the growing
          popularity of gold loan every year, it is necessary to know not only
          what a gold loan is but also how it operates, gold loan interest
          rates, and other associated data.{" "}
        </p>
      </section>
      <div className="bg-secondary bg-opacity-5 py-10">
        <section className="container px-6 md:px-14 flex flex-col gap-4">
          <h1 className="text-lg font-semibold">Gold Loan</h1>
          <p className="text-sm text-gray-500">
            The meaning of a gold loan is a secured loan taken by borrowers
            seeking to meet their immediate or long-term cash demands against
            gold. Lenders typically approve you for a loan in an amount that
            corresponds to a particular portion of the gold's value. You can
            receive your gold items back after paying them back in monthly
            installments. In this case, the borrower guarantees their gold
            jewellery as security. According to the Reserve Bank of India, the
            amount of the loan allowed may not exceed 90% of the gold's current
            market value. The gold that can be pledged ranges in carat weight
            from 22 to 24.
          </p>
          <p className="text-sm text-gray-500">
            Thus, it's a terrific option to meet your urgent financial demand,
            whether it's for a wedding, a family trip, or your child's school.
            In addition, many commercial and nationalised banks as well as NBFCs
            provide gold loans at low-interest rates.{" "}
          </p>
        </section>
      </div>{" "}
      <section className="container px-6 md:px-14 flex flex-col gap-4 py-10">
        <h1 className="text-lg font-semibold">How does a gold loan work?</h1>

        <p className="text-sm text-gray-500">
          The entire gold loan process is very comparable to other secured
          loans. In this scenario, you deliver your gold items and the necessary
          paperwork to a lender. The lender assesses the gold items and examines
          the submitted paperwork. The lender approves the loan amount based on
          the evaluations. According to the loan agreement, you repay the
          principal and interest payments and receive the returned gold items.
        </p>
      </section>
      <div className="bg-secondary bg-opacity-5 py-10">
        <section className="container px-6 md:px-14 flex flex-col gap-4">
          <h1 className="text-lg font-semibold">
            What is the interest rate on which lenders give the gold loan?
          </h1>
          <p className="text-sm text-gray-500">
            Since a loan given against gold is secured, it has a lower interest
            rate. The interest rates charged on these loans vary from one lender
            to another and rely on a number of variables, including the term of
            the loan, its size, etc. Also, it depends on where you are borrowing
            the gold: from a bank or an NBFC. In general, banks charge lower
            interest rates on loans against gold compared to NBFCs. Hence, if
            you intend to apply for a gold loan, resist the need to take the
            first offer you are given. Make your decision after comparing gold
            loan offers from at least two to three lending institutions.
          </p>
        </section>
      </div>{" "}
      <section className="container px-6 md:px-14 flex flex-col gap-4 py-10">
        <h1 className="text-lg font-semibold">How to get a gold loan?</h1>

        <p className="text-sm text-gray-500">
          You must first have your gold jewellery, coins, biscuits, or other
          tangible forms of gold on hand in order to apply for a gold mortgage
          loan. Choose a bank or NBFC that provides loans against gold products
          in India after that. The interest rate might vary from 7 to 29%
          annually, depending on your creditworthiness and CIBIL score.
        </p>
        <p className="text-sm text-gray-500">
          The lender (a bank or an NBFC) will examine your gold at home or in a
          physical branch of the business to ensure its purity. Identity and
          address evidence are two forms of paperwork needed for a gold mortgage
          loan. A PAN card, Aadhar card, current passport, or voter ID card are
          examples of acceptable government-issued documents.
        </p>
        <p className="text-sm text-gray-500">
          You are qualified to collect the money once the personal verification
          and the gold have both cleared. But, if the loan is not paid back, the
          gold that was pledged might not be recovered. If the lender offers
          online loan applications, you can do so as well.{" "}
        </p>
      </section>
      <div className="bg-secondary bg-opacity-5 py-10">
        <section className="container px-6 md:px-14 flex flex-col gap-4">
          <h1 className="text-lg font-semibold">
            What is the gold loan tenure?
          </h1>
          <p className="text-sm text-gray-500">
            One lending institution may have a different prepayment policy or
            tenure from than another. The typical range is 3 to 12 months. Some
            lenders may even let you renew it so you can extend the tenure,
            depending on the circumstances. Be sure you repay the loan amount on
            time because the gold loan has a shorter term than other sorts of
            loans. Defaulting on gold could result in the permanent loss of your
            gold possessions.
          </p>
        </section>
      </div>{" "}
    </main>
  );
};

export default Gold;
