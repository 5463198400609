import { FiInfo } from "react-icons/fi";
import { IoStar } from "react-icons/io5";
import HeadArrow from "./HeadArrow";
import { reviews } from "../../../utils/reviews";

const total = {
  5: "70",
  4: "20",
  3: "5",
  2: "3",
  1: "18",
};

const Review = () => {
  return (
    <div className="flex flex-col gap-3">
      <div className="flex justify-between flex-col md:flex-row md:items-center">
        <HeadArrow>Ratings and reviews</HeadArrow>
        <div className="flex gap-2 items-center hover:underline text-xs text-gray-500">
          Ratings and reviews are verified
          <FiInfo className="text-sm text-gray-600" />
        </div>
      </div>
      <TotalRatings />
      <UserReviews />
    </div>
  );
};

export default Review;

const UserReviews = () => {
  return (
    <div className="flex flex-col gap-10 mt-10">
      {reviews.map((review, index) => (
        <div className="flex gap-6" key={index}>
          <div className="flex flex-col gap-4">
            <div className="flex gap-4 items-center">
              <img
                src={review.img}
                alt="user"
                className="size-8 rounded-full"
              />

              <h1 className="text-sm text-foregroundDark">{review.userName}</h1>
            </div>
            <div className="flex gap-0.5 items-center mt-3">
              {new Array(5).fill(0).map((_, index) => {
                const rating = review.rating;
                if (index < Math.floor(rating)) {
                  return (
                    <IoStar
                      key={index}
                      className="text-gprimary-dark text-xs"
                    />
                  );
                }

                if (rating - index >= 0.5) {
                  return (
                    <svg
                      key={index}
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="12"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="lucide lucide-star-half fill-gprimary-dark stroke-gprimary-dark"
                    >
                      <path d="M12 17.8 5.8 21 7 14.1 2 9.3l7-1L12 2" />
                    </svg>
                  );
                }

                return <IoStar key={index} className="text-gray-200 text-xs" />;
              })}

              <p className="text-gray-500 text-xs ml-2">{review.date}</p>
            </div>
            <p className="text-gray-500 text-sm">{review.message}</p>

            <button className="hover:underline text-left w-auto text-xs text-gray-500 ">
              {review.helpful} people found this review helpful
            </button>

            <div className="flex gap-6 items-center mb-2">
              <p className="text-gray-500  text-xs">
                Did you find this helpful?
              </p>
              <div className="flex gap-3">
                <button className="text-sm text-gray-500 font-medium border px-4 py-0.5 rounded-full">
                  Yes
                </button>
                <button className="text-sm text-gray-500 font-medium border px-4 py-0.5 rounded-full">
                  No
                </button>
              </div>
            </div>

            {review?.reply?.map((reply, index) => (
              <div
                className="flex flex-col gap-3 ml-6 bg-gray-50 py-3 px-4 "
                key={index}
              >
                <div className="flex items-center gap-2 justify-between">
                  <h1 className="text-sm  text-foregroundDark">
                    {reply.userName}
                  </h1>
                  <p className="text-gray-500 text-xs">{reply.date}</p>
                </div>

                <p className="text-gray-500 text-sm">{reply.message}</p>
              </div>
            ))}
          </div>
        </div>
      ))}

      {/* <div className="flex gap-4">
        <SeeAllReview />
        <GiveRating />
      </div> */}
    </div>
  );
};

const TotalRatings = () => {
  return (
    <div className="mt-4 flex flex-col gap-10">
      <div className="flex gap-6">
        <div className="flex flex-col">
          <h1 className="text-7xl text-foregroundDark">4.9</h1>
          <div className="flex gap-0.5">
            {new Array(5).fill(0).map((_, index) => {
              const rating = 4.9;
              if (index < Math.floor(rating)) {
                return (
                  <IoStar key={index} className="text-gprimary-dark text-sm" />
                );
              }
              if (rating - index >= 0.5) {
                return (
                  <svg
                    key={index}
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="lucide lucide-star-half fill-gprimary-dark stroke-gprimary-dark"
                  >
                    <path d="M12 17.8 5.8 21 7 14.1 2 9.3l7-1L12 2" />
                  </svg>
                );
              }
            })}
          </div>
          <p className="text-gray-500 text-xs mt-3">39000 reviews</p>
        </div>
        <div className="flex flex-col flex-1 gap-0.5 ">
          {Object.keys(total)
            .reverse()
            .map((key, index) => (
              <div className="flex gap-3  items-center" key={index}>
                <p className="text-xs text-gray-500">{key}</p>
                <div className="flex-1 h-2.5 bg-gray-200 rounded-full">
                  <div
                    className="h-full bg-gprimary-dark rounded-full"
                    style={{
                      width: `${total[key]}%`,
                    }}
                  ></div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};
