import React from "react";
import Head from "../../../components/signup/Head";
import { useNavigate } from "react-router-dom";
import { BsPatchCheckFill } from "react-icons/bs";
import CustomCheckbox from "../../../components/ui/Checkbox";
import { useAuth } from "../../../utils/store";
import { useQuery } from "@tanstack/react-query";
import { getProfile, getSystemValues } from "../../../utils/api";
import { gettax } from "../../../utils/getgst";
const PaymentMethod = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { data, isLoading } = useQuery({
    queryKey: ["loanStatus"],
    queryFn: async () => {
      return await getProfile(user?.token);
    },
  });

  const { data: data2, isLoading: isLoading2 } = useQuery({
    queryKey: ["interest"],
    queryFn: async () => {
      return await getSystemValues();
    },
  });

  return (
    <main className="min-h-[100dvh] flex flex-col md:flex-row">
      <Head
        title="payment method"
        description={
          "Pay the followign loan taxes and fees  as required by the tax authorities."
        }
      />
      <form
        onSubmit={(e) => {
          e.preventDefault();
          navigate("/sign-up/payment-method/pay");
        }}
        className="flex flex-col flex-1 gap-8 px-6  justify-between py-8 bg-bggray"
      >
        <div className="flex flex-col   bg-white py-8 px-6 rounded-xl ">
          <p className="text-center font-semibold text-primary text-opacity-80 ">
            Congratulations !!{" "}
          </p>
          <p className="text-center text-primary  text-sm ">
            New loan eligbility for you has been
          </p>
          <p className="text-center text-3xl text-secondary font-bold my-6">
            &#8377; {data?.amount}
          </p>
          <p className="text-center text-primary  text-sm ">
            As per security tax rate rule, you get 18% GST need to cut
          </p>
        </div>{" "}
        <div className="flex flex-col   bg-white py-6 px-6 rounded-xl ">
          <p className="text-center font-semibold text-primary ">
            Your loan options
          </p>

          <p className="text-center text-xl text-secondary font-semibold ">
            &#8377; {data?.amount} / {data?.tenure}
          </p>
        </div>{" "}
        <div className="flex flex-col gap-4 ">
          <div className="flex justify-between">
            <p className="text-sm text-primary">Interest </p>
            <p className="text-sm font-semibold text-primary">
              &#8377;{data?.amount * (data2?.interest / 100)}
            </p>
          </div>
          <div className="flex justify-between">
            <p className="text-sm text-primary">Repayment amount </p>
            <p className="text-sm font-semibold text-primary">
              &#8377;{data?.amount + data?.amount * (data2?.interest / 100)}
            </p>
          </div>
          <div className="flex justify-between">
            <p className="text-sm text-primary">Recieved amount</p>
            <p className="text-sm font-semibold text-primary">
              {" "}
              &#8377;{data?.amount}
            </p>
          </div>
          <div className="flex justify-between">
            <p className="text-sm text-primary">GST</p>
            <p className="text-sm font-semibold text-primary">
              &#8377;{gettax(data?.amount, true, data2)}
            </p>
          </div>
        </div>
        <div className="flex gap-3 flex-col items-center">
          <CustomCheckbox
            label={
              <>
                I agree to the{" "}
                <span className="text-secondary">terms and conditions</span>
              </>
            }
          />

          <button
            type="submit"
            className="bg-secondary py-3 w-full rounded-full text-white text-sm font-semibold"
          >
            Submit
          </button>
        </div>
      </form>
    </main>
  );
};

export default PaymentMethod;
