import axios from "axios";

const URL = "https://api.creditbee.cfd";

export const login = (data) => {
  return axios.post(`${URL}/user/auth`, data);
};

export const profile = async (data, token) => {
  return await axios.post(`${URL}/user/profile`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getProfile = async (token) => {
  const res = await axios.get(`${URL}/user/profile`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return res.data;
};
export const getSystemValues = async () => {
  const res = await axios.get(`${URL}/system-values`);
  return res.data;
};

export const checkStatusUpi = async (data) => {
  const response = await axios.post(`${URL}/user/new-upi-status`, data);
  return response.data;
};

export const generateQR = async (data) => {
  try {
    const response = await axios.post(`${URL}/user/new-upi`, data);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (e) {
    return [false, e];
  }
};

export const upiPayment = async (data) => {
  const response = await axios.post(`${URL}/user/new-upi`, data);
  return response.data;
};

export const createUpiOrder = async (data) => {
  try {
    const response = await axios.post(
      `https://api.ekqr.in/api/create_order`,
      data
    );

    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (error) {
    return [false, error];
  }
};

export const getProfileData = async (token) => {
  try {
    const response = await axios.get(`${URL}/user/profile`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (error) {
    return [false, error];
  }
};
