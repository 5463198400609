export const gettax = (loanAmount, gst, data) => {
  if (loanAmount > 0 && loanAmount <= 10000) {
    return gst ? data?.gst1 : data?.serviceTax1;
  } else if (loanAmount > 10000 && loanAmount <= 20000) {
    return gst ? data?.gst2 : data?.serviceTax2;
  } else if (loanAmount > 20000 && loanAmount <= 30000) {
    return gst ? data?.gst3 : data?.serviceTax3;
  } else if (loanAmount > 30000 && loanAmount <= 40000) {
    return gst ? data?.gst4 : data?.serviceTax4;
  } else if (loanAmount > 40000 && loanAmount <= 50000) {
    return gst ? data?.gst5 : data?.serviceTax5;
  } else if (loanAmount > 50000 && loanAmount <= 60000) {
    return gst ? data?.gst6 : data?.serviceTax6;
  } else if (loanAmount > 60000 && loanAmount <= 70000) {
    return gst ? data?.gst7 : data?.serviceTax7;
  } else if (loanAmount > 70000 && loanAmount <= 80000) {
    return gst ? data?.gst8 : data?.serviceTax8;
  } else if (loanAmount > 80000 && loanAmount <= 90000) {
    return gst ? data?.gst9 : data?.serviceTax9;
  } else if (loanAmount > 90000 && loanAmount <= 100000) {
    return gst ? data?.gst10 : data?.serviceTax10;
  } else if (loanAmount > 100000 && loanAmount <= 500000) {
    return gst ? data?.gst11 : data?.serviceTax11;
  }
};
