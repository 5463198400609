import React from "react";
import CustomSelect from "../../../components/ui/Select";
import Input from "../../../components/ui/Input";
import CustomCheckbox from "../../../components/ui/Checkbox";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useMutation } from "@tanstack/react-query";
import { profile } from "../../../utils/api";
import { useAuth } from "../../../utils/store";
import Loading from "../../../components/Loading";
import { toast } from "react-hot-toast";

const About = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm();

  const { mutate, isLoading } = useMutation({
    mutationFn: (data) => {
      return profile(
        {
          ...data,
          profileDetailAdded: true,
        },
        user?.token
      );
    },

    onSuccess: (res) => {
      toast.success("Profile details added successfully");
      navigate("/sign-up/pan-card-details", {
        replace: true,
      });
    },
  });
  return (
    <main className="md:flex">
      <section className="h-48 md:flex-[1.5] md:h-auto bg-primary px-6 py-6 flex items-end md:items-start md:justify-center ">
        <h1 className="text-xl md:sticky md:top-1/2 text-white uppercase ">
          Tell us about yourself
        </h1>
      </section>
      <form
        onSubmit={handleSubmit((data) => {
          mutate(data);
        })}
        className="flex flex-col flex-1 bg-bggray gap-8 px-6 py-6"
      >
        <Input
          label={"First Name"}
          caption={"As per PAN CARD"}
          error={errors?.firstName?.message}
          register={register("firstName", {
            required: "First name is required",
            minLength: {
              value: 3,
              message: "First name should be atleast 3 characters",
            },
          })}
        />
        <Input
          label={"Last Name"}
          error={errors?.lastName?.message}
          caption={"As per PAN CARD"}
          register={register("lastName", {
            required: "Last name is required",
            minLength: {
              value: 3,
              message: "Last name should be atleast 3 characters",
            },
          })}
        />
        <Input
          label={"Email"}
          error={errors?.email?.message}
          register={register("email", {
            required: "Email is required",
            pattern: {
              value: /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/i,
              message: "Invalid email address",
            },
          })}
        />
        <CustomSelect
          label={"Education"}
          control={control}
          name="education"
          error={errors?.education?.message}
          options={[
            { value: "10th", label: "10th" },
            { value: "12th", label: "12th" },
            { value: "Graduation", label: "Graduation" },
            { value: "Post Graduation", label: "Post Graduation" },
          ]}
        />
        <CustomSelect
          label={"Your Employee type"}
          control={control}
          error={errors?.employeeType?.message}
          name="employeeType"
          options={[
            { value: "Salaried", label: "Salaried" },
            { value: "Self Employed", label: "Self Employed" },
          ]}
        />
        <Input
          label={"Monthly income"}
          caption={"5000 INR"}
          error={errors?.monthlyIncome?.message}
          register={register("monthlyIncome", {
            required: "Monthly income is required",
            pattern: {
              value: /^[0-9]*$/,
              message: "Please enter valid monthly income",
            },
          })}
        />
        <Input
          label={"Annual family income "}
          caption={"5000 INR"}
          error={errors?.annualFamilyIncome?.message}
          register={register("annualFamilyIncome", {
            required: "Annual family income is required",
            pattern: {
              value: /^[0-9]*$/,
              message: "Please enter valid annual family income",
            },
          })}
        />
        {/* "0-2 LPA",
    "2-4 LPA",
    "4-6 LPA",
    "6-8 LPA",
    "8-10 LPA",
    "10-12 LPA",
    "12-14 LPA" */}

        <CustomSelect
          label={"Annual Income "}
          control={control}
          name="annualIncome"
          error={errors?.annualIncome?.message}
          options={[
            { value: "0-2 LPA", label: "0-2 LPA" },
            { value: "2-4 LPA", label: "2-4 LPA" },
            { value: "4-6 LPA", label: "4-6 LPA" },
            { value: "6-8 LPA", label: "6-8 LPA" },

            { value: "8-10 LPA", label: "8-10 LPA" },
            { value: "10-12 LPA", label: "10-12 LPA" },
            { value: "12-14 LPA", label: "12-14 LPA" },
          ]}
        />

        <CustomCheckbox label="I declare the above information is correct and true. I allow BharatCash Finance(P) LTD to be my authorised representative & fetch my credit information from  CIBIL / Experian / Equifax. " />
        <button
          type="submit"
          disabled={isLoading}
          className="bg-secondary py-3 rounded-full flex items-center justify-center text-white text-sm font-semibold"
        >
          {isLoading ? <Loading bg="bg-white" /> : "Submit"}
        </button>
      </form>
    </main>
  );
};

export default About;
