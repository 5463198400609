import React, { useEffect } from "react";
import Head from "../../components/signup/Head";

const LoanUpdate = () => {
  return (
    <main className="min-h-[100dvh] flex flex-col md:flex-row">
      <Head title="Loan update" />
      <div className="flex flex-col flex-1 gap-8 px-4 md:px-6  justify-between py-8 bg-bggray">
        <div className="bg-white rounded-xl px-4 py-10 flex flex-col gap-6 items-center justify-center">
          <img
            src="/images/checksit.svg"
            alt=""
            className="object-contain rounded-lg md:w-2/3 self-center "
          />
          <div className="flex flex-col items-center justify-center gap-6">
            <p className="text-center text-gray-500 text-sm ">
              We are extremely sorry to inform you that our Lender Partners have
              made a decision to hold back your application. We've captured your
              interest and will try our best to get back shortly with an offer.
              We thank you for your patience.
            </p>
          </div>
          <ul className="flex flex-col gap-6 mt-4 mb-4">
            {[
              "90% of the users who have applied after 1 month have been approved.",
              "You can also apply after 1 month",
              "At that time you do not have to pay any fees.",
            ].map((item, index) => (
              <li key={index} className="flex gap-4 ">
                <div className="w-3 h-3 bg-secondaryDark rounded-full mt-1" />
                <p className="text-sm flex-1 text-gray-600">{item}</p>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </main>
  );
};

export default LoanUpdate;
