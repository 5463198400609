import React, { useEffect, useState } from "react";
import OTPInput from "react-otp-input";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Wrapper from "../../components/Wrapper";
import Loading from "../../components/Loading";
import { useMutation } from "@tanstack/react-query";
import { login } from "../../utils/api";
import { useAuth } from "../../utils/store";

const Otp = () => {
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const user = useAuth((state) => state.user);
  const setUser = useAuth((state) => state.setUser);
  const {
    state: { phoneNumber },
  } = useLocation();

  const { mutate: sendOtp } = useMutation({
    mutationFn: () => {
      return login({ phoneNumber });
    },
    onSuccess: (res) => {
      setUser(res?.data);
      const user = res?.data?.user;
      if (res?.data?.isAccountExist) {
        if (user?.user?.bankInformationAdded) {
          navigate("/sign-up/payment-method", {
            replace: true,
          });
          // PaymentPage
        } else if (user?.user?.photoAdded) {
          navigate("/sign-up/bank-info", {
            replace: true,
          });
          // BankInformationPage
        } else if (user?.user?.loanStatusApprove) {
          //  PhotoIdFrontScreen
          navigate("/sign-up/loan-status", {
            replace: true,
          });
        } else if (user?.user?.amountDetailAdded) {
          //  SelectRequirementsPage
          navigate("/sign-up/select-requirements", {
            replace: true,
          });
        } else if (user?.user?.pancardDetailAdded) {
          // AmountSelectionPage,
          navigate("/sign-up/pan-card-details", {
            replace: true,
          });
        } else if (user?.user?.profileDetailAdded) {
          //  PanCardDetailsPage,
          navigate("/sign-up/about", {
            replace: true,
          });
        }
      } else {
        navigate("/sign-up/about");
      }
    },
  });

  useEffect(() => {
    if (!phoneNumber) {
      navigate("/");
      return;
    }

    setLoading(true);

    const otp = Math.floor(100000 + Math.random() * 900000);
    const time = setTimeout(() => {
      setOtp(otp);
      if (user) {
        if (user?.user?.bankInformationAdded) {
          navigate("/sign-up/payment-method", {
            replace: true,
          });
          // PaymentPage
        } else if (user?.user?.photoAdded) {
          navigate("/sign-up/bank-info", {
            replace: true,
          });
          // BankInformationPage
        } else if (user?.user?.loanStatusApprove) {
          //  PhotoIdFrontScreen
          navigate("/sign-up/loan-status", {
            replace: true,
          });
        } else if (user?.user?.amountDetailAdded) {
          //  SelectRequirementsPage
          navigate("/sign-up/select-requirements", {
            replace: true,
          });
        } else if (user?.user?.pancardDetailAdded) {
          // AmountSelectionPage,
          navigate("/sign-up/pan-card-details", {
            replace: true,
          });
        } else if (user?.user?.profileDetailAdded) {
          //  PanCardDetailsPage,
          navigate("/sign-up/about", {
            replace: true,
          });
        }
      } else {
        sendOtp();
      }
      setLoading(false);
    }, 5000);

    return () => clearTimeout(time);
  }, [phoneNumber]);
  return (
    <Wrapper>
      <section className="flex flex-col  gap-4 ">
        <h1 className="text-4xl  text-secondary uppercase font-akshar">
        BharatCash
        </h1>
        <h2 className="text-lg text-white font-semibold  ">OTP Sent</h2>
        <p className="text-white text-opacity-50">
          Please enter 6 digit OTP <br />
          number below
        </p>
      </section>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          navigate("/sign-up/about");
        }}
        className="flex flex-col gap-4 flex-1 justify-between mt-6"
      >
        <OTPInput
          value={otp}
          onChange={setOtp}
          numInputs={6}
          renderSeparator={<span className="w-2"></span>}
          renderInput={(props) => (
            <input
              {...props}
              disabled={loading}
              className="flex-1 bg-primaryLight py-3 rounded-full text-white text-opacity-50 focus:outline-none"
            />
          )}
        />
        <div className="flex flex-col gap-6">
          <button
            type="submit"
            className="bg-secondary py-3 flex items-center justify-center rounded-full text-white text-sm font-semibold"
          >
            {loading ? <Loading bg={"bg-white"} /> : "Verify & Proceed"}
          </button>
          <button className="bg-transparent text-white text-opacity-50 text-sm font-semibold">
            Resend OTP
          </button>
        </div>
      </form>
    </Wrapper>
  );
};

export default Otp;
