import {
  MdLockOutline,
  MdOutlineCloudUpload,
  MdOutlineShare,
} from "react-icons/md";
import { RiDeleteBin6Line } from "react-icons/ri";
const Privacy = () => {
  const privacy = [
    {
      icon: MdOutlineShare,
      title: "This app may share these data types with third parties",
      description: "Financial info, Photos and videos and 4 others",
    },
    {
      icon: MdOutlineCloudUpload,
      title: "This app may collect these data types",
      description: "Location, Personal info and 6 others",
    },
    {
      icon: MdLockOutline,
      title: "Data is encrypted in transit",
    },
    {
      icon: RiDeleteBin6Line,
      title: "You can request that data be deleted",
    },
  ];
  return (
    <div className="border mt-4 px-6 py-6 rounded-lg flex flex-col gap-6">
      {privacy.map((item, index) => (
        <div key={index} className="flex gap-4 text-gray-500 ">
          <item.icon className=" text-xl" />
          <div className="flex flex-col">
            <h3 className="text-sm">{item.title}</h3>
            <p className=" text-xs">{item.description}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Privacy;
