const Consumer = () => {
  return (
    <main className="flex flex-col  py-6">
      <h1 className="text-2xl font-semibold container px-6 md:px-14 ">
        Consumer Loan
      </h1>
      <section className="container px-6 md:px-14 flex flex-col gap-4 py-10">
        <h1 className="text-lg font-semibold">What is a Consumer Loan?</h1>
        <p className="text-sm text-gray-500">
          A personal loan is an unsecured loan that you can avail from banks,
          fintech companies and other non-banking financial companies. Although
          offered to all earning individuals for a wide range of loan amounts,
          personal loans are becoming a popular choice of finance among
          borrowers below the age of 35 and for finance below Rs 50,000. While
          the personal loan demand fell among the young borrowers due to the
          pandemic, millennial participation in the personal loan market
          increased from 27% to 41% in the last two years. The demand for small
          ticket personal loans had an annual increase of 162% by March 2020.
        </p>
        <p className="text-sm text-gray-500">
          Statistics – CreditScape-Personal Loans Dec 2020 by CRIF High Mark){" "}
        </p>
      </section>
      <div className="bg-secondary bg-opacity-5 py-10">
        <section className="container px-6 md:px-14 flex flex-col gap-4">
          <h1 className="text-lg font-semibold">
            What are the benefits of a Consumer Loan?
          </h1>
          <p className="text-sm text-gray-500">
            Some of the major benefits of a personal loan include,
          </p>
          <ul className="list-disc list-outside text-sm text-gray-500 flex flex-col gap-3">
            <li>
              A personal loan application process is quite simple and can be
              availed with minimal documentation.
            </li>
            <li>
              Most banks and NBFCs offer pre-approved personal loans which
              eliminate the need for loan application processing and eligibility
              checks.
            </li>
            <li>
              Personal loan repayment terms can be reasonably flexible, with a
              wide range of repayment tenor.
            </li>
            <li>
              A personal loan can be availed for any purpose. Unlike most other
              loans, a personal loan can be used to meet any financial need, be
              it travel plans, medical expenses, home renovation, debt
              consolidation or even a temporary cash crunch.
            </li>
            <li>
              A personal loan doesnʼt require any collateral security or
              pledging.
            </li>
          </ul>
        </section>
      </div>
      <section className="container px-6 md:px-14 flex flex-col gap-4 py-10">
        <h1 className="text-lg font-semibold">
          What is the eligibility criteria for a Consumer Loan?
        </h1>
        <p className="text-sm text-gray-500">
          Among the commonly preferred eligibility criteria are,
        </p>
        <ul className="list-disc list-outside text-sm text-gray-500 flex flex-col gap-3">
          <li>
            The borrower must be within a specified age group, which can be
            around 18-21 to 60-65 years.
          </li>
          <li>
            Predominantly offered to salaried individuals employed with
            recognised MNCs, public or private companies. Personal loans are
            also offered to self-employed applicants with proof of business
            existence, ITRs and other financial documents.
          </li>
          <li>
            The applicant must possess the required KYC and other documents.
          </li>
          <li>
            Personal loans are generally offered to residents of India only.
          </li>
          <li>
            Many lenders have minimum income benchmarks for personal loan
            approval.
          </li>
          <li>
            A specified number of years in employment or business existence is
            required.
          </li>
          <li>
            The CIBIL score of the applicant is also considered while accepting
            the loan and deciding the amount, interest rate and tenor.{" "}
          </li>
        </ul>
      </section>
      <div className="bg-secondary bg-opacity-5 py-10">
        <section className="container px-6 md:px-14 flex flex-col gap-4">
          <h1 className="text-lg font-semibold">
            What are the documents typically required for a Consumer Loan?
          </h1>
          <p className="text-sm text-gray-500">
            The documentation required for a personal loan mostly comprises of
            identity, address and income-related documents like,{" "}
          </p>
          <ul className="list-disc list-outside text-sm text-gray-500 flex flex-col gap-3">
            <li>
              KYC documents like Aadhaar, PAN, Voter ID, Passport,driving
              license etc.
            </li>
            <li>Employment Identity proof.</li>
            <li>Salary slips.</li>
            <li>Bank statements.</li>
            <li>Passport size photo.</li>
            <li>Income tax return.</li>
            <li>Proof of business (self-employed applicant).</li>
            <li>Financial statements (self-employed applicant).</li>
          </ul>
        </section>
      </div>{" "}
      <section className="container px-6 md:px-14 flex flex-col gap-4 py-10">
        <h1 className="text-lg font-semibold">
          Where can you use a Consumer Loan?
        </h1>
        <p className="text-sm text-gray-500">
          There are a few important factors that you can consider while
          selecting a personal loan ideal for you. It includes,{" "}
        </p>
        <ul className="list-disc list-outside text-sm text-gray-500 flex flex-col gap-3">
          <li>
            The amount of EMI that you need to pay every month. This will be
            determined by the amount of the loan, the rate of interest and the
            tenor of the loan. For a given loan amount and rate of interest, the
            EMI will be higher for a shorter loan tenor, and it will decrease as
            the tenor of the loan increases.
          </li>
          <li>
            The processing and disbursal time of the personal loan is important,
            particularly if the need is urgent. The lending process is generally
            faster in the case of account holders of the lender.
          </li>
          <li>
            The interest rate of the personal loan is an important factor as it
            will decide your cost of borrowing. Different lenders charge
            different rates of interest. However, the fluctuations in interest
            rate is influenced by the interest rate of the RBI.You can compare
            different lenders based on the rate of interest rate they offer.{" "}
          </li>
          <li>
            Apart from the interest rate, a loan can involve other costs like
            loan processing fees, stamp duty, credit assessment fees etc. Your
            overall cost of borrowing can increase if these incidental charges
            are on the higher side.{" "}
          </li>
          <li>
            Personal loans are easily available, but be careful in selecting the
            right loan amount. Excessive debts can inflate the EMI and affect
            your repayment capacity. Calculate the amount you need, the EMI that
            you will need to pay regularly and your existing financial
            obligations while selecting the loan amount.
          </li>
          <li>
            Understand the terms and conditions of the lender while availing of
            a personal loan. The policy on prepayment,acceptance of part-payment
            and foreclosure, redrawing of funds, services that are chargeable
            etc. are some of the terms and conditions typically found in
            personal loan documents.
          </li>
        </ul>
      </section>
      <div className="bg-secondary bg-opacity-5 py-10">
        <section className="container px-6 md:px-14 flex flex-col gap-4">
          <h1 className="text-lg font-semibold">
            Can you foreclose a Consumer Loan?
          </h1>
          <p className="text-sm text-gray-500">
            Pre-closure of a personal loan can attract a pre-closure/
            foreclosure penalty. Ideally, you should familiarise yourself with
            this charge while applying for a personal loan. You have to also
            check if your lender allows foreclosure or not.
          </p>
          <ul className="list-disc list-outside text-sm text-gray-500 flex flex-col gap-3">
            <li>
              In case your lender doesnʼt have an online foreclosure option, you
              have to visit your nearest branch and fill the pre-closure form.
            </li>
            <li>
              You will have to submit your KYC documents, loan documents and
              bank statement reflecting the latest EMI payments.
            </li>
            <li>
              After submitting the form and the required documents, you can make
              the remaining payment by cheque demand draft or cash.
            </li>
          </ul>
        </section>
      </div>{" "}
      <section className="container px-6 md:px-14 flex flex-col gap-4 py-10">
        <h1 className="text-lg font-semibold">
          Things to avoid while applying for a Consumer Loan
        </h1>
        <p className="text-sm text-gray-500">
          Here are a few residual points that are worth remembering while
          applying for a personal loan,
        </p>
        <ul className="list-disc list-outside text-sm text-gray-500 flex flex-col gap-3">
          <li>
            Donʼt apply for personal loans at multiple places, or in quick
            succession after rejection. In both cases, your credit score can be
            affected.
          </li>
          <li>
            Always disclose all existing liabilities while applying for a
            personal loan.
          </li>
          <li>
            Donʼt focus on the interest rate alone. Factors like terms and
            conditions and other charges are also crucial in dictating the cost
            of borrowing.
          </li>
          <li>
            Always go for the affordable EMI rather than the lowest EMI. Lowest
            EMI generally means the longest loan tenor, and therefore high total
            interest accrued.
          </li>
          <li>
            Find out your loan eligibility before applying for the loan to avoid
            the trouble of rejection.
          </li>
        </ul>
      </section>
    </main>
  );
};

export default Consumer;
