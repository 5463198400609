import { useEffect } from "react";
import { Link } from "react-router-dom";
import phone from "../assets/phone.svg";
import coperate from "../assets/home/coperate.svg";
import credit from "../assets/home/credit.svg";
import customer from "../assets/home/customer.svg";
import download from "../assets/home/download.svg";
import money from "../assets/home/money.svg";
import world from "../assets/home/world.svg";
import human from "../assets/home/human.svg";
import { useQuery } from "@tanstack/react-query";
import { getSystemValues } from "../utils/api";
import { useNavigate } from "react-router-dom";
import LoadingS from "../components/ui/Loading";

const Home = () => {
  const { data: systemValues, isLoading: systemValuesLoading } = useQuery({
    queryKey: ["systemValues"],
    queryFn: async () => {
      return await getSystemValues();
    },
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (systemValues?.isEnable) {
      navigate("/information", {
        replace: true,
      });
    }
  }, [navigate, systemValues]);

  if (systemValuesLoading) return <LoadingS />;

  return (
    <main className="flex flex-col  py-14 flex-1">
      <section className="flex gap-10 items-center container px-6 md:px-14 flex-col-reverse md:flex-row ">
        <div className="flex flex-1 flex-col items-start">
          <h1 className="text-2xl   font-semibold ">
            Personal Loan - Apply for an Instant Personal Loan Online
          </h1>
          <p className="text-sm  text-gray-500 mt-2">
            Manage all of life’s instant upgrades with Online Personal Loan from
            BharatCash. Get up to ₹5 lakh in just 2 minutes.
          </p>
          <Link
            to="/login"
            className="bg-secondary text-white px-8 py-2 rounded-full text-sm  font-semibold mt-6"
          >
            Apply Now
          </Link>
          <p className="text-sm  text-gray-500 mt-6">
            Already have an account?{" "}
            <Link to="/login" className="text-secondary">
              Login
            </Link>
          </p>
        </div>
        <div className="flex flex-1">
          <img src={phone} alt="" className="md:w-[90%]" />
        </div>
      </section>
      <section className="bg-secondary  bg-opacity-10  px-6 md:px-14 mt-10">
        <div className="grid grid-cols-2 md:flex gap-10 justify-between py-6 container items-center ">
          {[
            {
              title: "App Downloads",
              value: "1.5M+",
              image: download,
            },
            {
              title: "Loans Given",
              value: "5.5M+",
              image: credit,
            },
            {
              title: "Money Disbursed",
              value: "₹15,000+ Crores",
              image: money,
            },
            {
              title: "Happy Customers",
              value: "20 Lacs+",
              image: customer,
            },
            {
              title: "Corporate Tie-ups",
              value: "5,000+",
              image: coperate,
            },
          ].map((item, index) => (
            <div className="flex gap-2 items-center  " key={index}>
              <img src={item.image} alt="" className="w-12" />
              <div className="flex flex-col">
                <h3 className="font-bold text-sm">{item.value}</h3>

                <p className="text-xs text-gray-500">{item.title}</p>
              </div>
            </div>
          ))}
        </div>
      </section>

      <section className="flex flex-col items-center container px-6 md:px-14 mt-10">
        <h1 className="text-xl   font-semibold text-center">
          Apply for a Personal Loan for All Your Needs
        </h1>
        <p className="text-sm  text-gray-500 mt-6 text-center">
          Whether it’s a last-minute travel plan or a medical emergency – an
          online personal loan is an easy solution to all your financial needs.
          You can apply for a personal loan online without submitting physical
          paperwork. With over five lakh happy customers, BharatCash is emerging as
          one of the top platforms for getting instant Personal loans with a
          simple and safe application process.
        </p>
        <p className="text-sm  text-gray-500 mt-2 text-center">
        BharatCash is one of India’s trusted digital lending platforms where you can
          apply for instant loan, get approval instantly and money will be
          disbursed within 2 minutes. Just check the eligibility criteria,
          credit limit on the BharatCash app and get the cash credited to your bank
          account directly. With BharatCash, you get benefits like quick disbursal,
          flexible tenure, minimal documentation and attractive interest rates.
        </p>
      </section>
      <section className=" bg-gray-50 mt-10">
        <div className="flex  items-center flex-col md:flex-row container px-6 md:px-14  gap-10 bg-gray-50 py-14">
          <div className="flex flex-1 flex-col items-center ">
            <h1 className="text-xl   font-semibold text-center">
              Personal Loan Eligibility Criteria
            </h1>
            <p className="text-sm  text-gray-500 mt-6 ">
            BharatCash offers the best personal loans with easy-to-meet eligibility
              criteria. Unlike other banks or non-bank financial corporations,
              in BharatCash, you can apply for instant personal loan online with
              minimal documentation.
            </p>
            <p className="text-sm  text-gray-500 mt-2 ">
            BharatCash is India’s trusted platform for seamless and secure loans.
              Applying for instant personal loans by logging into our official
              website or download the application.
            </p>
          </div>
          <div className="flex flex-1 flex-col gap-6 ">
            {[
              {
                image: human,
                des: "Age: Between 21 and 55 years",
              },
              {
                image: money,
                des: "Metro cities: Minimum salary - ₹18,000 Non-metro cities: Minimum salary - ₹15,000",
              },
              {
                image: world,
                des: "Residence: Must be a resident of India",
              },
            ].map((item, index) => (
              <div
                className="flex gap-6 items-center bg-white shadow-lg  px-4 py-4 rounded-lg "
                key={index}
              >
                <img src={item.image} alt="" className="w-12" />
                <div className="flex flex-col text-sm font-semibold">
                  {item.des}
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <section className="flex flex-col  items-center container px-6 md:px-14 mt-10 gap-10">
        <h1 className="text-xl   font-semibold text-center">
          Documents Required for a Personal Loan
        </h1>
        <div className="flex     md:px-14 flex-col md:flex-row  gap-10">
          <div className="flex flex-1 flex-col  ">
            <p className="text-sm  text-gray-500 mt-6 ">
              The quick online loan application process from BharatCash is super easy
              and quick. The best part? You get a loan in 2 minutes. The
              documents required for a personal loan depend on one lender to
              another.
            </p>
            <p className="text-sm  text-gray-500 mt-2 ">
              At BharatCash, get easy personal loan by just uploading some basic
              documents as mentioned on the right.
            </p>
            <p className="text-sm  text-gray-500 mt-2 ">
              Get your instant personal loan now!
            </p>
          </div>
          <div className="flex flex-1 flex-col gap-6 ">
            {[
              {
                title: "A Selfie",
                des: "Age: Between 21 and 55 years",
              },
              {
                title: "Identity Proof",
                des: "Passport/Aadhaar card/PAN card/driver's license",
              },
              {
                title: "Address proof",
                des: "Passport/a rental agreement/utility bills/voter’s ID",
              },
              {
                title: "Proof of Income",
                des: "Bank statements and salary stubs for the last 3 to 6 months",
              },
            ].map((item, index) => (
              <div className="flex gap-3 items-center  rounded-lg " key={index}>
                <div className="w-2 h-2 bg-secondary rounded-full" />
                <div className="flex flex-col text-sm font-semibold">
                  <h1>{item.title}</h1>
                  <p className="text-xs text-gray-500">{item.des}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </main>
  );
};

export default Home;
