import { Link } from "react-router-dom";
import { meta } from "../../../utils/static";

const Footer = () => {
  return (
    <div className="container bg-white">
      <footer className="  pt-14 pb-10 border-t flex flex-wrap flex-row gap-6 items-center">
        {meta.footer.map((link, index) => (
          <Link key={index} to={link.url} className=" text-xs hover:underline">
            {link.title}
          </Link>
        ))}
        <div className="flex gap-2 items-center ml-2 lg:ml-auto">
          <img src="/india.png" alt="india" className="h-6 w-6" />

          <p className="text-xs text-gray-500">
            India (English (United Kingdom))
          </p>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
