import React from "react";
import Head from "../../../components/signup/Head";
import { BsPatchCheckFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import Input from "../../../components/ui/Input";
import { useForm } from "react-hook-form";
import { profile } from "../../../utils/api";
import { useMutation } from "@tanstack/react-query";
import { useAuth } from "../../../utils/store";
import { toast } from "react-hot-toast";
import Loading from "../../../components/Loading";

const BankInfomation = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { mutate, isLoading } = useMutation({
    mutationFn: async (data) => {
      return await profile(
        {
          ...data,
          bankInformationAdded: true,
        },
        user?.token
      );
    },
    onSuccess: (res) => {
      toast.success("Bank information added successfully");
      navigate("/sign-up/payment-method", {
        replace: true,
      });
    },
  });

  return (
    <main className="flex flex-col min-h-[100dvh] md:flex-row ">
      <Head
        title="Bank Information"
        description={
          "Please be sure to input the correct bank card information so that the loan can be obtained."
        }
      />{" "}
      <form
        onSubmit={handleSubmit((data) => {
          mutate(data);
        })}
        className="flex flex-col flex-1 gap-8 px-6  justify-between py-8 bg-bggray"
      >
        <div className="flex flex-col gap-8  ">
          <Input
            label={"Bank account number"}
            error={errors?.accountNumber?.message}
            placeholder={"ex:0000000000000"}
            register={register("accountNumber", {
              required: "Account number is required",
            })}
          />
          <Input
            label={"IFSC code"}
            error={errors?.ifsc?.message}
            placeholder={"ex:0000000000000"}
            register={register("ifsc", {
              required: "IFSC code is required",
            })}
          />
          <Input
            label={"Bank name"}
            error={errors?.bankName?.message}
            placeholder={"ex: State bank of india "}
            register={register("bankName", {
              required: "Bank name is required",
            })}
          />
        </div>{" "}
        <button
          type="submit"
          disabled={isLoading}
          className="bg-secondary flex items-center justify-center py-3 rounded-full text-white text-sm font-semibold"
        >
          {isLoading ? <Loading bg="bg-white" /> : "Submit"}
        </button>
      </form>
    </main>
  );
};

export default BankInfomation;
