import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import CustomCheckbox from "../../components/ui/Checkbox";
import { useAuth } from "../../utils/store";
import { useQuery } from "@tanstack/react-query";
import { getProfile, getSystemValues } from "../../utils/api";
import LoadingS from "../../components/ui/Loading";
import Navbar from "../../components/Navbar";

const Login = () => {
  const { user, setUser } = useAuth();
  const { data, isFetching: isLoading } = useQuery({
    queryKey: ["loanStatus"],
    queryFn: async () => {
      return await getProfile(user?.token);
    },

    enabled: !!user && !!user?.token,
  });

  const router = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { data: systemValues, isLoading: systemValuesLoading } = useQuery({
    queryKey: ["systemValues"],
    queryFn: async () => {
      return await getSystemValues();
    },
  });

  const navigate = useNavigate();
  useEffect(() => {
    if (user) {
      setUser({
        ...user,
        user: data,
      });
      if (data?.paymentDone) {
        //  PanCardDetailsPage,
        navigate("/profile/status", {
          replace: true,
        });
      } else if (data?.bankInformationAdded) {
        navigate("/sign-up/payment-method", {
          replace: true,
        });
        // PaymentPage
      } else if (data?.photoAdded) {
        navigate("/sign-up/bank-info", {
          replace: true,
        });
        // BankInformationPage
      } else if (data?.loanStatusApprove) {
        //  PhotoIdFrontScreen
        navigate("/sign-up/loan-status", {
          replace: true,
        });
      } else if (data?.amountDetailAdded) {
        //  SelectRequirementsPage
        navigate("/sign-up/select-requirements", {
          replace: true,
        });
      } else if (data?.pancardDetailAdded) {
        // AmountSelectionPage,
        navigate("/sign-up/pan-card-details", {
          replace: true,
        });
      } else if (data?.profileDetailAdded) {
        //  PanCardDetailsPage,
        navigate("/sign-up/about", {
          replace: true,
        });
      }
    }
  }, [navigate, data]);

  useEffect(() => {
    if (systemValues?.isEnable) {
      navigate("/information", {
        replace: true,
      });
    }
  }, [navigate, systemValues]);

  if (systemValuesLoading || isLoading) {
    return (
      <main className="flex-1 bg-white">
        <Navbar />
        {<LoadingS />}
      </main>
    );
  }

  return (
    <main className="h-[100dvh] bg-primary gap-6   px-6 flex flex-col md:flex-row pt-14 pb-10 md:w-[50rem] md:mx-auto ">
      <section className="md:flex flex-1 hidden">
        <img
          src="https://images.unsplash.com/photo-1565514158882-617325fbd873?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80"
          alt=""
          className="object-cover rounded-lg"
        />
      </section>
      <div className="flex flex-col flex-1 ">
        <section className="flex flex-col  gap-4 ">
          <h1 className="text-4xl  text-secondary uppercase font-akshar">
          BharatCash
          </h1>
          <h2 className="text-lg text-white font-semibold  ">
            Login your account
          </h2>
          <p className="text-white text-opacity-50">
            Login to your account and get <br /> instant loan{" "}
          </p>
        </section>
        <form
          onSubmit={handleSubmit((data) => {
            router("/verify", { state: data });
          })}
          className="flex flex-col gap-4 flex-1 justify-between"
        >
          <div className="flex flex-col gap-3 mt-3">
            {errors.phoneNumber && (
              <p className="text-red-500 text-sm">
                {errors.phoneNumber.message}
              </p>
            )}
            <fieldset className="flex  gap-2 px-3 bg-primaryLight py-2 rounded-full">
              <div className="px-6 bg-[#2C2A31] text-sm py-2 rounded-full text-white text-opacity-50">
                +91
              </div>
              <input
                className="bg-transparent text-sm text-white text-opacity-50 flex-1 outline-none appearance-none"
                type="number"
                placeholder="Mobile no."
                //keyboard number
                pattern="[0-9]*"
                inputMode="numeric"
                {...register("phoneNumber", {
                  required: "Mobile number is required",
                  minLength: {
                    value: 10,
                    message: "Mobile number must be 10 digits",
                  },
                  maxLength: {
                    value: 10,
                    message: "Mobile number must be 10 digits",
                  },
                  pattern: {
                    value: /^[0-9]*$/,
                    message: "Please enter valid mobile number",
                  },
                })}
              />
            </fieldset>
          </div>
          <div className="flex flex-col gap-6">
            <CustomCheckbox
              label={
                <p className="text-white">
                  {" "}
                  I agree to the{" "}
                  <span className="text-secondary">Terms & Conditions</span>
                </p>
              }
            />

            <button
              type="submit"
              className="bg-secondary py-3 rounded-full text-white text-sm font-semibold"
            >
              Login
            </button>
          </div>
        </form>
      </div>
    </main>
  );
};

export default Login;
