import Contact from "../components/install/home/Contact";
import HeadArrow from "../components/install/home/HeadArrow";
import Hero from "../components/install/home/hero/Hero";
import ImageGallery from "../components/install/home/ImageGallery";
import New from "../components/install/home/New";
import Privacy from "../components/install/home/Privacy";
import Review from "../components/install/home/Review";
import BottomBar from "../components/install/layout/BottomBar";
import Footer from "../components/install/layout/Footer";
import Navbar from "../components/install/layout/Navbar";
import { meta } from "../utils/static";

const Page = () => {
  return (
    <>
      <Navbar />

      <main className="bg-white">
        <Hero />
        <section className="flex gap-8 flex-col md:flex-row container py-10">
          <div className="flex-1 md:w-[70%] flex flex-col gap-6">
            <ImageGallery />
            <div className="flex flex-col gap-3">
              <HeadArrow>About this app</HeadArrow>
              <p className="text-gray-500 text-sm">{meta.about}</p>
              <div className="flex gap-1 flex-col mt-5">
                <h2 className="text-sm font-medium">Updated on</h2>
                <p className="text-gray-500 text-sm">{meta.lastUpdated}</p>
              </div>
              <div className="border mt-5 w-fit rounded-full px-5 py-1.5 text-gray-500 font-medium text-sm">
                {meta.category}
              </div>
            </div>
            <div className="flex flex-col gap-3">
              <HeadArrow>Data safety</HeadArrow>
              <p className="text-gray-500 text-sm">
                Safety starts with understanding how developers collect and
                share your data. Data privacy and security practices may vary
                based on your use, region and age The developer provided this
                information and may update it over time.
              </p>
              <Privacy />
            </div>
            <Review />
            <New />
          </div>
          <Contact />
        </section>
      </main>
      <Footer />
      <BottomBar />
    </>
  );
};

export default Page;
