import { Route, Routes } from "react-router-dom";
import Login from "./pages/auth/Login";
import Otp from "./pages/auth/Otp";
import About from "./pages/auth/signup/About";
import PanCardDetails from "./pages/auth/signup/PanCardDetails";
import SelectRequirements from "./pages/auth/signup/SelectRequirements";
import LoanStatus from "./pages/auth/signup/LoanStatus";
import TakePhoto from "./pages/auth/signup/TakePhoto";
import BankInfomation from "./pages/auth/signup/BankInfomation";
import PaymentMethod from "./pages/auth/signup/PaymentMethod";
import PaymentMethodPay from "./pages/auth/signup/PaymentPay";
import Profile from "./pages/account/Profile";
import AboutUS from "./pages/info/About";
import PrivacyPolicy from "./pages/info/PrivacyPolicy";
import RefundPolicy from "./pages/info/RefundPolicy";
import Terms from "./pages/info/Terms";
import Home from "./pages/Home";
import Layout from "./components/Navbar";
import Consumer from "./pages/loans/Consumer";
import Business from "./pages/loans/Business";
import Car from "./pages/loans/Car";
import Gold from "./pages/loans/Gold";
import Information from "./pages/info/Information";
import Install from "./pages/Install";
import Status from "./pages/account/Status";
import Update from "./pages/account/Update";
import AccountStatement from "./pages/account/AccountStatement";
import LoanUpdate from "./pages/account/LoanUpdate";

function App() {
  return (
    <div className="bg-primary">
      <Routes>
        <Route path="/" element={<Install />} />
        <Route path="/install" element={<Install />} />
        <Route
          path="/consumer-loan"
          element={
            <Layout>
              <Consumer />
            </Layout>
          }
        />
        <Route
          path="/business-loan"
          element={
            <Layout>
              <Business />
            </Layout>
          }
        />
        <Route
          path="/gold-loan"
          element={
            <Layout>
              <Gold />
            </Layout>
          }
        />
        <Route
          path="/car-loan"
          element={
            <Layout>
              <Car />
            </Layout>
          }
        />
        <Route path="/login" element={<Login />} />
        <Route path="/verify" element={<Otp />} />
        <Route path="/sign-up/about" element={<About />} />
        <Route path="/info/about-us" element={<AboutUS />} />
        <Route path="/info/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/info/refund-policy" element={<RefundPolicy />} />
        <Route path="/info/terms-and-conditions" element={<Terms />} />
        <Route path="/sign-up/pan-card-details" element={<PanCardDetails />} />
        <Route
          path="/sign-up/select-requirements"
          element={<SelectRequirements />}
        />
        <Route
          path="/information"
          element={
            <Layout>
              <Information />
            </Layout>
          }
        />
        <Route path="/profile/status" element={<Status />} />
        <Route path="/profile/edit" element={<Update />} />
        <Route path="/profile/loan-update" element={<LoanUpdate />} />
        <Route path="/sign-up/loan-status" element={<LoanStatus />} />
        <Route path="/sign-up/take-photo" element={<TakePhoto />} />
        <Route path="/sign-up/bank-info" element={<BankInfomation />} />
        <Route path="/sign-up/payment-method" element={<PaymentMethod />} />
        <Route
          path="/sign-up/payment-method/pay"
          element={<PaymentMethodPay />}
        />

        <Route path="/account/profile" element={<Profile />} />
        <Route path="/profile/statement" element={<AccountStatement />} />
      </Routes>
    </div>
  );
}

export default App;
