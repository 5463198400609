import React, { useEffect } from "react";
import Head from "../../components/signup/Head";
import { BsPatchCheckFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import Input from "../../components/ui/Input";
import { useForm } from "react-hook-form";
import { profile } from "../../utils/api";
import { useMutation } from "@tanstack/react-query";
import { useAuth } from "../../utils/store";
import { toast } from "react-hot-toast";
import Loading from "../../components/Loading";
import { useProfile, useSystemValues } from "../../utils/queries";

const AccountStatement = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [updated, setUpdated] = React.useState(false);
  const { data: currentProfile } = useProfile();
  const { data: systemValues, isLoading: systemValuesLoading } =
    useSystemValues();
  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { mutate, isLoading } = useMutation({
    mutationFn: async (data) => {
      await profile(data, user?.token);
      return data;
    },
    onSuccess: (data) => {
      setUpdated(true);
      if (data?.accountStatementAdded) {
        navigate("/profile/loan-update", { replace: true });
      }
    },
  });
  const file = watch("accountStatement");
  useEffect(() => {
    if (
      currentProfile?.accountStatementTime &&
      currentProfile?.accountStatementEndTime !== null
    ) {
      setUpdated(true);
      const currentTime = new Date().getTime();
      const endTime = new Date(
        currentProfile?.accountStatementEndTime
      ).getTime();
      if (currentTime > endTime) {
        mutate({
          accountStatementAdded: true,
        });
      }
    }
  }, [currentProfile, navigate]);

  return (
    <main className="flex flex-col min-h-[100dvh] md:flex-row ">
      <Head
        message={
          "Amount will be credited to your account immediately after the account statement is approved"
        }
        icon="/images/bank.svg"
        title="Account statement"
      >
        We thank you for your patience.
        <br />
        <br />
        We need a little more information about your account, for that you will
        need to upload your account statement.
      </Head>

      <form
        onSubmit={handleSubmit((data) => {
          const time = new Date().toUTCString();

          const utcTimePlusMins = new Date(time).setMinutes(
            new Date(time).getMinutes() +
              systemValues?.durationForAccountStatement
          );

          const formData = new FormData();
          formData.append("accountStatement", data.accountStatement[0]);
          formData.append("accountStatementTime", time);
          formData.append(
            "accountStatementEndTime",
            new Date(utcTimePlusMins).toUTCString()
          );
          mutate(formData);
        })}
        className="flex flex-col flex-1 gap-8   justify-between  bg-bggray"
      >
        <div className="flex flex-col gap-8  px-6 pt-8">
          <input
            type="file"
            id="file"
            name="accountStatement"
            className="hidden"
            {...register("accountStatement", {
              required: "Account statement is required",
            })}
          />
          <label
            htmlFor="file"
            className="flex items-center justify-center gap-2 text-sm  bg-white rounded-full py-3 px-4  cursor-pointer"
          >
            <svg
              width="19"
              height="19"
              viewBox="0 0 19 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_0_970)">
                <path
                  d="M0.75 2.9686V16.5248C0.75 16.8161 0.986109 17.0522 1.27734 17.0522H9.22266V2.44125H1.27734C0.986109 2.44125 0.75 2.67736 0.75 2.9686ZM4.45898 7.32896V7.20493C4.48493 6.50598 5.48797 6.50651 5.51367 7.20493V7.31106C5.83078 7.39568 6.07206 7.54482 6.2065 7.67929C6.41578 7.88186 6.4212 8.2157 6.21866 8.42495C6.01609 8.63424 5.68225 8.63965 5.473 8.43712C5.13778 8.18297 4.41342 8.24249 4.384 8.89942C4.384 8.95001 4.384 8.97311 4.4754 9.02641C4.62232 9.11212 4.87239 9.17462 5.11426 9.23509C5.42779 9.31346 5.752 9.39453 6.02875 9.55593C7.20086 10.2672 6.53711 11.852 5.51371 12.1645V12.2885C5.48776 12.9874 4.48472 12.9869 4.45902 12.2885V12.1823C4.14191 12.0977 3.90063 11.9486 3.7662 11.8141C3.55691 11.6116 3.5515 11.2777 3.75403 11.0685C3.9566 10.8592 4.29045 10.8538 4.4997 11.0563C4.83491 11.3104 5.55927 11.2509 5.5887 10.594C5.5887 10.5434 5.5887 10.5203 5.49729 10.467C5.35037 10.3813 5.1003 10.3188 4.85843 10.2583C4.54491 10.18 4.2207 10.0989 3.94395 9.93748C2.77184 9.22616 3.43559 7.64139 4.45898 7.32896Z"
                  fill="black"
                />
                <path
                  d="M18.2227 2.44125H10.2773V17.0522H18.2227C18.5139 17.0522 18.75 16.8161 18.75 16.5248V2.9686C18.75 2.67736 18.5139 2.44125 18.2227 2.44125ZM16.4906 14.5104H12.5367C11.8378 14.4844 11.8383 13.4814 12.5367 13.4557H16.4906C17.1896 13.4817 17.189 14.4847 16.4906 14.5104ZM16.4906 11.6862H12.5367C11.8378 11.6602 11.8383 10.6572 12.5367 10.6315H16.4906C17.1896 10.6574 17.189 11.6605 16.4906 11.6862ZM16.4906 8.86195H12.5367C11.8378 8.836 11.8383 7.83296 12.5367 7.80726H16.4906C17.1896 7.8332 17.189 8.83628 16.4906 8.86195ZM16.4906 6.03774H12.5367C11.8378 6.01179 11.8383 5.00875 12.5367 4.98305H16.4906C17.1896 5.009 17.189 6.01204 16.4906 6.03774Z"
                  fill="black"
                />
              </g>
              <defs>
                <clipPath id="clip0_0_970">
                  <rect
                    width="18"
                    height="18"
                    fill="white"
                    transform="translate(0.75 0.746735)"
                  />
                </clipPath>
              </defs>
            </svg>
            {file?.[0]?.name || "Upload your document"}
          </label>
        </div>{" "}
        {updated ? (
          <div className="bg-[#15926C] px-4 py-6 flex flex-col gap-4 items-center justify-center">
            <div className="flex gap-3">
              <svg
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.2773 12.9594L13.6623 16.3444L24.9455 5.06123"
                  stroke="white"
                  stroke-width="2.25663"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M23.8173 14.0878V21.986C23.8173 22.5845 23.5795 23.1585 23.1563 23.5817C22.7331 24.0049 22.1591 24.2426 21.5606 24.2426H5.7642C5.1657 24.2426 4.59172 24.0049 4.16852 23.5817C3.74532 23.1585 3.50757 22.5845 3.50757 21.986V6.18955C3.50757 5.59106 3.74532 5.01708 4.16852 4.59387C4.59172 4.17067 5.1657 3.93292 5.7642 3.93292H18.1757"
                  stroke="white"
                  stroke-width="2.25663"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <p className="text-white  font-semibold">
                Document submitted successfully
              </p>
            </div>
            <p className="text-white text-center text-sm">
              It may take 2 to 5 working days to review the account
              statement, so please wait.
              <br />
              <br />
              For this you keep checking the app after some time
              <br />
              <br />
              (Saturday & Sunday is non working days)
            </p>
          </div>
        ) : (
          <div className="pb-8 px-6">
            <button
              type="submit"
              disabled={isLoading}
              className="bg-secondary w-full flex items-center justify-center py-3 rounded-full text-white text-sm font-semibold"
            >
              {isLoading ? <Loading bg="bg-white" /> : "Submit"}
            </button>
            <p className="text-center mt-8 text-gray-500 font-medium text-sm">
              Due to some issues in our app, account details are taking more
              time to update than before, still it will be updated in our system
              within 5 or 6 working days
              <br />
              <br />
              (Saturday & Sunday is non working days)
            </p>
          </div>
        )}
      </form>
    </main>
  );
};

export default AccountStatement;
