import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";

import { atomWithStorage } from "jotai/utils";

export const installedPwa = atomWithStorage("installed", false);

export const useAuth = create()(
  devtools(
    persist(
      (set) => ({
        user: null,

        setUser: (user) => set({ user }),
        logout: () => set({ user: null }),
      }),
      {
        name: "bharatcash",
        getStorage: () => localStorage,
      }
    )
  )
);
