import React, { useEffect } from "react";
import Head from "../../components/signup/Head";
import { Link, useNavigate } from "react-router-dom";
import { BiMessage, BiSolidEdit } from "react-icons/bi";
import { AiFillExclamationCircle, AiFillMessage } from "react-icons/ai";
import { BsFillTelephoneFill } from "react-icons/bs";
import { RiLogoutCircleLine } from "react-icons/ri";
import { MdAccountBalance, MdPrivacyTip, MdUpdate } from "react-icons/md";
import { GiReceiveMoney } from "react-icons/gi";
import { useAuth } from "../../utils/store";
import { getProfile, getSystemValues, profile } from "../../utils/api";
import { useMutation, useQuery } from "@tanstack/react-query";
const Profile = () => {
  const { user, logout } = useAuth();

  const navigate = useNavigate();
  useEffect(() => {
    if (!user) {
      navigate("/login");
    }
  }, [user]);

  const { data: data2, isLoading: isLoading2 } = useQuery({
    queryKey: ["systemValues"],
    queryFn: async () => {
      return await getSystemValues();
    },
  });

  const { data, isLoading } = useQuery({
    queryKey: ["profile"],
    queryFn: async () => {
      return await getProfile(user?.token);
    },
  });

  return (
    <main className="flex flex-col  min-h-[100dvh]">
      <Head title="Profile" />
      <div className="flex py-6 flex-col gap-6 px-6 flex-1 bg-bggray">
        <section className="bg-white items-center rounded-lg flex gap-6 py-6 px-4">
          <img
            src="https://img.freepik.com/free-psd/3d-icon-social-media-app_23-2150049569.jpg?w=826&t=st=1692942066~exp=1692942666~hmac=0feffc095a6a429b1728dab05f71a087a4a3dddcfeac28f306f24cbbf1efa1cb"
            alt=""
            className="w-20 h-20 rounded-full object-cover"
          />
          <div className="flex flex-col gap-2">
            <h1 className="text-lg text-primary font-bold">
              {data?.firstName} {data?.lastName}
            </h1>
            <div className="flex gap-3">
              <div className="text-xs text-secondary font-semibold">
                Loan record
              </div>
              <div className="text-xs text-secondary font-semibold">
                Repayment plan
              </div>
            </div>
          </div>
        </section>
        <section className=" px-4 flex flex-col gap-8">
          {[
            {
              title: "About us ",
              link: "/info/about-us",
              icon: (
                <AiFillExclamationCircle className="text-secondaryDark text-lg" />
              ),
            },
            // {
            //   title: "Contact us  ",
            //   link: "/account/profile/edit",
            //   icon: <BsFillTelephoneFill className="text-secondaryDark " />,
            // },
            {
              title: "Privacy policies",
              link: "/info/privacy-policy",
              icon: <MdPrivacyTip className="text-secondaryDark text-lg " />,
            },
            {
              title: "Terms & condition",
              link: "/info/terms-and-conditions",
              icon: <BiSolidEdit className="text-secondaryDark text-lg " />,
            },
            {
              title: "Refund policy",
              link: "/info/refund-policy",
              icon: <GiReceiveMoney className="text-secondaryDark text-lg " />,
            },
          ].map((item, i) => (
            <Link
              key={i}
              to={item.link}
              className="  flex items-center justify-start gap-3"
            >
              <div className="bg-white w-10 h-10 items-center justify-center flex rounded-full">
                {item.icon}
              </div>
              <p className="text-primary text-opacity-80 text-sm font-semibold">
                {item.title}
              </p>
            </Link>
          ))}
          <button
            onClick={() => logout()}
            className="  flex items-center justify-start gap-3"
          >
            <div className="bg-white w-10 h-10 items-center justify-center flex rounded-full">
              <RiLogoutCircleLine className="text-secondaryDark text-lg " />
            </div>
            <p className="text-primary text-opacity-80 text-sm font-semibold">
              Log out
            </p>
          </button>
        </section>
      </div>
    </main>
  );
};

export default Profile;
