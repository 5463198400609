import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import { meta } from "../../../utils/static";

const ImageGallery = () => {
  return (
    <Swiper
      spaceBetween={10}
      slidesPerView={3.8}
      breakpoints={{
        200: {
          slidesPerView: 2.5,
        },
        800: {
          slidesPerView: 3.5,
        },
        1200: {
          slidesPerView: 3.8,
        },
      }}
      className="w-full "
    >
      {meta.screenshots.map((image, index) => (
        <SwiperSlide key={index}>
          <img src={image} alt={`-${index}`} className="rounded-lg" />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default ImageGallery;
