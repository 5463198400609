import { meta } from "../../../utils/static";
import { MdLocalPhone, MdOutlineMail } from "react-icons/md";
import { PiGlobe } from "react-icons/pi";

const Contact = () => {
  const links = [
    {
      title: "Website",
      url: meta.websiteUrl,
      icon: PiGlobe,
    },
    {
      title: "Email",
      url: meta.email,
      icon: MdOutlineMail,
    },
    {
      title: "Contact",
      url: meta.phoneNumber,
      icon: MdLocalPhone,
    },
  ];
  return (
    <div className=" flex flex-col gap-3 md:w-[30%] pt-8">
      <h2 className="text-lg  font-medium">Developer contact</h2>
      {links.map((link, index) => (
        <a key={index} href={link.url} className="flex gap-1 ml-4 mt-1 ">
          <link.icon className="text-black text-2xl" />
          <div className="flex flex-col text-sm">
            <p className="font-medium">{link.title}</p>
            <p className="text-gray-500">{link.url}</p>
          </div>
        </a>
      ))}
    </div>
  );
};

export default Contact;
