import { meta } from "../../../utils/static";
import { useState } from "react";

const New = () => {
  return (
    <div className="mt-4 flex flex-col gap-4">
      <h1 className="text-lg font-medium text-foregroundDark">
        What&apos;s new
      </h1>
      <div>
        {meta.whatsNew.map((faq, index) => (
          <Faq key={index} question={faq.question} answer={faq.answer} />
        ))}
      </div>
    </div>
  );
};

export default New;

const Faq = ({ question, answer }) => {
  const [show, setShow] = useState(false);
  return (
    <div className="flex flex-col gap-3">
      <button
        onClick={() => answer && setShow(!show)}
        className="text-sm text-left font-medium transition-all duration-500 text-gray-500 bg-gray-100 py-4 px-2 hover:bg-gray-300"
      >
        - {question}
      </button>
      {show && (
        <p
          className="text-foregroundDark text-sm pl-6 pb-6 leading-tight"
          dangerouslySetInnerHTML={{
            __html: answer,
          }}
        />
      )}
    </div>
  );
};
