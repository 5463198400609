import * as Checkbox from "@radix-ui/react-checkbox";
import { BiCheck } from "react-icons/bi";
const CustomCheckbox = ({ label }) => {
  return (
    <div className="flex items-start ">
      <Checkbox.Root
        className=" hover:bg-gray-100 flex min-h-[20px] min-w-[20px] appearance-none items-center justify-center rounded-full border bg-gray-200  outline-none "
        defaultChecked
        id="c1"
      >
        <Checkbox.Indicator className="text-secondary">
          <BiCheck />
        </Checkbox.Indicator>
      </Checkbox.Root>
      <label
        className="pl-[15px] text-xs leading-snug text-primary"
        htmlFor="c1"
      >
        {label}
      </label>
    </div>
  );
};

export default CustomCheckbox;
