import React from "react";

const Wrapper = ({ children }) => {
  return (
    <main className="min-h-[100dvh] bg-primary gap-6   px-6 flex flex-col md:flex-row pt-14 pb-10 md:w-[50rem] md:mx-auto ">
      <section className="md:flex flex-1 hidden">
        <img
          src="https://images.unsplash.com/photo-1565514158882-617325fbd873?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80"
          alt=""
          className="object-cover rounded-lg"
        />
      </section>
      <div className="flex flex-col flex-1 ">{children}</div>
    </main>
  );
};

export default Wrapper;
