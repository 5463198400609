export const reviews = [
  {
    userName: "Ishita Iyer",
    img: "https://play-lh.googleusercontent.com/a-/ACB-R5RlYMUyQird_hDJmXRdGLi2RhK7CDMpqSPC2XWe=s64-rw",
    rating: 5,
    date: "19 April 2023",
    message:
      "Get Instant Personal Loan on your finger tip, bharatcash is an easy and quick personal loan app, trusted by over thousands of people across India. You can get an easy and hassle-free loan of up to ₹ 2 lakhs with BharatCash Instant Loan App.",
    helpful: 280,
  },
  {
    userName: "Manish Dubey",
    img: "https://play-lh.googleusercontent.com/a-/ACB-R5Rqqs9EpspBB4UcWYCMt6ZQnYMvU2W3prZKjpMmpw=s64-rw",
    rating: 4,
    date: "4 April 2023",
    message:
      "This app saved me so much time! I applied for a loan and got the money in my account within minutes.",
    helpful: 5026,
  },
  {
    userName: "Priya Sharma",
    img: "https://play-lh.googleusercontent.com/a-/ACB-R5TurQVl4OYcEXNFt7vO-Dqx7VtFqytxcZ3kVIoH=s64-rw",
    rating: 5,
    date: "8 April 2023",
    message:
      "A convenient and reliable lending platform offering flexible and hassle-free personal loans with competitive interest rates and flexible repayment options.",
    helpful: 1220,
  },
];
