import React from "react";
import Head from "../../../components/signup/Head";
import { useNavigate } from "react-router-dom";
import { BsPatchCheckFill } from "react-icons/bs";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useAuth } from "../../../utils/store";
import { getProfile, getSystemValues, profile } from "../../../utils/api";
import Loading from "../../../components/Loading";
import { toast } from "react-hot-toast";

const LoanStatus = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { data, isLoading } = useQuery({
    queryKey: ["loanStatus"],
    queryFn: async () => {
      return await getProfile(user?.token);
    },
  });

  const { data: data2, isLoading: isLoading2 } = useQuery({
    queryKey: ["interest"],
    queryFn: async () => {
      return await getSystemValues();
    },
  });

  const { mutate, isLoading: isLoading3 } = useMutation({
    mutationFn: async (data) => {
      return await profile(data, user?.token);
    },
    onSuccess: (res) => {
      toast.success("Loan Approved");
      // navigate("/sign-up/take-photo", {
      //   replace: true,
      // });
      navigate("/sign-up/bank-info", {
        replace: true,
      });
    },
  });

  return (
    <main className="min-h-[100dvh] flex flex-col md:flex-row">
      <Head title="BharatCash" logo />
      <form
        onSubmit={(e) => {
          e.preventDefault();
          if (isLoading) return;
          if (isLoading2) return;
          if (!user) return;

          mutate({
            loanStatusApprove: true,
            photoAdded: true,
          });
        }}
        className="flex flex-col flex-1 gap-8 px-6  justify-between py-8 bg-bggray"
      >
        <div className="flex flex-col gap-3 mt-14 bg-white py-8 px-6 rounded-3xl ">
          <div className="p-8 rounded-full mx-auto bg-white shadow-sm -mt-20">
            <BsPatchCheckFill className="text-4xl text-secondary" />
          </div>
          <p className="text-center text-lg text-secondary mt-4 ">Great</p>
          <h1 className="text-center text-2xl text-primary font-semibold">
            Loan Approved
          </h1>
          <p className="text-center text-sm text-primary">
            Below is Your Summary
          </p>
          <div className="flex flex-col gap-4 mt-8">
            <div className="flex justify-between">
              <p className="text-sm text-primary">Interest rate </p>
              <p className="text-sm font-semibold text-primary">
                {data2?.interest}% per annum
              </p>
            </div>
            <div className="flex justify-between">
              <p className="text-sm text-primary">Loan Amount </p>
              <p className="text-sm font-semibold text-primary">
                &#8377;{data?.amount}
              </p>
            </div>
            <div className="flex justify-between">
              <p className="text-sm text-primary">Tenure </p>
              <p className="text-sm font-semibold text-primary">
                {data?.tenure}
              </p>
            </div>
          </div>
          <h3 className="text-center font-semibold text-xl text-primary mt-8">
            Total Loan Amount
          </h3>
          <p className="text-center text-3xl text-secondary font-bold">
            &#8377;
            {data?.amount}
          </p>
        </div>{" "}
        <button
          type="submit"
          disabled={isLoading || isLoading2 || isLoading3}
          className="bg-secondary py-3 flex items-center justify-center rounded-full text-white text-sm font-semibold"
        >
          {isLoading || isLoading2 || isLoading3 ? (
            <Loading bg="bg-white" />
          ) : (
            "Continue"
          )}
        </button>
      </form>
    </main>
  );
};

export default LoanStatus;
