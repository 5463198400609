import React from "react";
import * as RadioGroup from "@radix-ui/react-radio-group";

const RadioButton = ({ label }) => {
  return (
    <div className="flex items-center">
      <RadioGroup.Item
        className="bg-bggray w-[18px] h-[18px] rounded-full  outline-none cursor-default"
        value={label}
        id={label}
      >
        <RadioGroup.Indicator className="flex items-center justify-center w-full h-full relative after:content-[''] after:block after:w-[8px] after:h-[8px] after:rounded-[50%] after:bg-secondary" />
      </RadioGroup.Item>
      <label
        className="text-primary  text-xs leading-none pl-[8px]"
        htmlFor={label}
      >
        {label}
      </label>
    </div>
  );
};

export default RadioButton;
