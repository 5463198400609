import React from "react";

const Car = () => {
  return (
    <main className="flex flex-col  py-6">
      <h1 className="text-2xl font-semibold container px-6 md:px-14 ">
        Car Loan
      </h1>

      <section className="container px-6 md:px-14 flex flex-col gap-4 py-10">
        <h1 className="text-lg font-semibold">
          Features and Benefits of Car Loan
        </h1>

        <ul className="list-disc list-outside text-sm flex flex-col gap-3">
          <li>Get financing for purchasing new and used cars.</li>
          <li>
            The financing can go up to 85%-90% of the on-road price of the car.
            Some banks offer up to 100% financing on the vehicle’s on-road price
            to certain conditions.
          </li>
          <li>The loan tenure can range from one year up to seven years. </li>
          <li>
            The loan amount can be up to three times the annual income of the
            applicant.
          </li>
          <li>Some lenders offer instant financing facilities for cars.</li>
          <li>
            You may get additional discounts and offers if you choose to
            purchase a car from the dealer or manufacturer the bank has a tie-up
            with.
          </li>
          <li>
            The car purchased through financing will be held as collateral until
            the loan is repaid.
          </li>
          <li>
            The repayment structure most commonly followed for a car loan is
            equated monthly instalments (EMI).{" "}
          </li>
        </ul>
      </section>
      <div className="bg-secondary bg-opacity-5 py-10">
        <section className="container px-6 md:px-14 flex flex-col gap-4">
          <h1 className="text-lg font-semibold">Factors Affecting Car Loan</h1>

          <ul className="list-disc list-outside text-sm flex flex-col gap-3">
            <li>
              <span className="font-semibold text-base text-black">
                Credit Score:{" "}
              </span>
              <br />
              Your lender will enquire about your credit score to understand
              your creditworthiness. Based on your report and score, the lender
              will take a call on the loan amount they are willing to lend you
              and the associated terms and conditions.
            </li>
            <li>
              <span className="font-semibold text-base text-black">
                Debt-to-income ratio{" "}
              </span>
              <br />
              Lenders assess your income and the commitments you have to cater
              to at the end of the month to see if the new car loan and its EMIs
              fit in your budget. The lender will determine your ability to take
              a new loan and stand by it through the debt-to-income (DTI) ratio.
              If you have a high DTI score, you will get a lower loan amount
              irrespective of your income. There is the risk of your loan terms
              being stringent.
            </li>
            <li>
              <span className="font-semibold text-base text-black">
                Down payment{" "}
              </span>
              <br />
              Every car loan comes with a defined margin. Margin, here, means
              the amount of money or the percentage of the on-road car price
              that you will have to pay from your pocket. Though there are 100%
              financing loan schemes available in the market, they are subject
              to conditions. <br />
              <br />
              It is always favourable for you to save some money and use it as a
              downpayment so you can borrow less and pay less interest to the
              bank, reducing the total cost of car ownership. Lenders also
              prefer that you make a certain downpayment from your end. This
              gives the lenders a sense of guarantee that you are good at
              planning and managing your money well and will not bail out of the
              repayments suddenly.
            </li>
            <li>
              <span className="font-semibold text-base text-black">
                Age of vehicle
              </span>
              <br />
              In the case of used car loans, the age of the vehicle matters a
              lot in deciding the interest rate; it is a deciding factor in
              accepting or rejecting the loan application.
            </li>
          </ul>
        </section>
      </div>

      <section className="container px-6 md:px-14 flex flex-col gap-6 py-10">
        <h1 className="text-lg font-semibold">Eligibility Criteria</h1>
        <p className="text-sm">
          Certain eligibility conditions should be met to go for a car loan, and
          one must make sure they have a proper understanding of that. Let’s see
          what these factors that will decide if you are eligible to buy a car
          are:
        </p>
        <table className="table-auto md:w-[40rem] rounded-lg   overflow-hidden  ">
          <thead>
            <tr className="border  border-gray-200 text-gray-700 bg-gray-100">
              <th className="py-2 rounded-tl-lg border-r">Details</th>
              <th className="py-2">Salaried</th>
              <th className="py-2">Self-employed</th>
            </tr>
          </thead>
          <tbody>
            {/* Age	21 – 60 years	18 – 65 years
Annual income	Rs 25,000	Minimum turnover of Rs 40 lakh
Credit Score	700	700
Loan Tenure	Up to 7 years	Up to 7 years
Loan to Value Ratio	Up to 100%	Up to 100% */}
            <tr className="border-b border-gray-200">
              <td className="py-2 border-x px-2 font-semibold">Age</td>
              <td className="py-2 border-x px-2">21 – 60 years</td>
              <td className="py-2 border-x px-2">18 – 65 years</td>
            </tr>
            <tr className="border-b border-gray-200">
              <td className="py-2 border-x px-2 font-semibold">
                Annual income
              </td>
              <td className="py-2 border-x px-2">Rs 25,000</td>
              <td className="py-2 border-x px-2">
                Minimum turnover of Rs 40 lakh
              </td>
            </tr>
            <tr className="border-b border-gray-200">
              <td className="py-2 border-x px-2 font-semibold">Credit Score</td>
              <td className="py-2 border-x px-2">700</td>
              <td className="py-2 border-x px-2">700</td>
            </tr>
            <tr className="border-b border-gray-200">
              <td className="py-2 border-x px-2 font-semibold">Loan Tenure</td>
              <td className="py-2 border-x px-2">Up to 7 years</td>
              <td className="py-2 border-x px-2">Up to 7 years</td>
            </tr>
            <tr className="border-b border-gray-200">
              <td className="py-2 border-x px-2 font-semibold">
                Loan to Value Ratio
              </td>
              <td className="py-2 border-x px-2">Up to 100%</td>
              <td className="py-2 border-x px-2">Up to 100%</td>
            </tr>
          </tbody>
        </table>
      </section>
      <div className="bg-secondary bg-opacity-5 py-10">
        <section className="container px-6 md:px-14 flex flex-col gap-4">
          <h1 className="text-lg font-semibold">Documents Required</h1>

          <ul className="list-disc list-outside text-sm flex flex-col gap-6 text-gray-900">
            <li>
              Photo ID and Age Proof
              <ul className="list-disc mt-3 list-inside text-sm flex flex-col gap-3">
                <li>PAN card</li>
                <li>Passport</li>
                <li>Driving license (mandatory)</li>
              </ul>
            </li>
            <li>Signed application form</li>
            <li>Three passport size photographs</li>
            <li>
              Residence Proof
              <ul className="list-disc mt-3 list-inside text-sm flex flex-col gap-3">
                <li>Valid passport</li>
                <li>Voter ID card</li>
                <li>Driving license</li>
                <li>Postpaid utility bill (gas bill and electricity bill)</li>
                <li>Updated passbook or bank account statement</li>
                <li>Notarised registered rent agreement</li>
              </ul>
            </li>
            <li>Bank statement for the last six months</li>
            <li>
              Salaried individual
              <ul className="list-disc mt-3 list-inside text-sm flex flex-col gap-3">
                <li>Last three months’ salary slips</li>
                <li>Last three months’ salary slips</li>
              </ul>
            </li>
            <li>
              Self-employed individual
              <ul className="list-disc mt-3 list-inside text-sm flex flex-col gap-3">
                <li>
                  Balance sheet and profit and loss account, computation of
                  income for last two years.
                </li>
                <li>
                  Income Tax Returns – last two years for applicants, 26AS,
                  Traces
                </li>
              </ul>
            </li>
            <li>
              Business proof :
              <ul className="list-disc mt-3 list-inside text-sm flex flex-col gap-3">
                <li>
                  Gomasta License, Registration Certificate, Service Tax
                  Registration, among others
                </li>
              </ul>
            </li>
            <li>IT Assessment/Clearance Certificate</li>
            <li>
              Income Tax Challans/TDS Certificate (Form 16A)/Form 26 AS for
              income declared in ITR.
            </li>
          </ul>
        </section>
      </div>
    </main>
  );
};

export default Car;
