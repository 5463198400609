import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
const LoadingS = () => {
  return (
    <main className="p-4 flex flex-col gap-3 ">
      <Skeleton height={300} />
      <Skeleton count={4} height={200} containerClassName="flex  gap-2" />
    </main>
  );
};

export default LoadingS;
